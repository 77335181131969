import React from 'react';
import { ToolbarDraft } from './ToolbarDraft';
import { ToolbarLearning } from './ToolbarLearning';

export interface FlashcardToolbarProps {
    mode: "test" | "learn" | "edit";
    draft: boolean;
    onResponse: (result: boolean) => void;
    onSave: () => Promise<any>;
    onCancel: () => void;
    onReverse: () => void;
    onClose: () => void;
    canSave: boolean;
}

export const FlashcardToolbar = (props: FlashcardToolbarProps) => {
    console.log('FlashcardToolbar rendering')
    return (
        <div className="flashcard-toolbar">
            {props.draft && <ToolbarDraft canSave={props.canSave} onCancel={props.onCancel} onSave={props.onSave} />}
            {!props.draft && <ToolbarLearning mode={props.mode} onClose={props.onClose} onResponse={props.onResponse} onReverse={props.onReverse} />}
        </div>
    );
};