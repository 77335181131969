export interface IAlphabesDefinition {
    greek: string[],
    english: string[],
    japanese: string[],
    katakana: string[],
    romaji: string[],
}

const alphabesDefinition = {
    greek: ['Α', 'α', 'Β', 'β', 'Γ', 'γ', 'Δ', 'δ', 'Ε', 'ε', 'Ζ', 'ζ', 'Η', 'η', 'Θ', 'θ', 'Ι', 'ι', 'Κ', 'κ', 'Λ', 'λ', 'Μ', 'μ', 'Ν', 'ν', 'Ξ', 'ξ', 'Ο', 'ο', 'Π', 'π', 'Ρ', 'ρ', 'Σ', 'σ', 'ς', 'Τ', 'τ', 'Υ', 'υ', 'Φ', 'φ', 'Χ', 'χ', 'Ψ', 'ψ', 'Ω', 'ω'],
    english: ['A', 'a', 'B', 'b', 'G', 'g', 'D', 'd', 'E', 'e', 'Z', 'z', 'E', 'e', 'Th', 'th', 'I', 'i', 'K', 'k', 'L', 'l', 'M', 'm', 'N', 'n', 'X', 'x', 'O', 'o', 'P', 'p', 'R', 'r', 'S', 's', 's', 'T', 't', 'Y', 'y', 'Ph', 'ph', 'Kh', 'kh', 'Ps', 'ps', 'O', 'o'],
    japanese: ['あ', 'い', 'う', 'え', 'お', 'か', 'き', 'く', 'け', 'こ', 'きゃ', 'きゅ', 'きょ', 'さ', 'し', 'す', 'せ', 'そ', 'しゃ', 'しゅ', 'しょ', 'た', 'ち', 'つ', 'て', 'と', 'ちゃ', 'ちゅ', 'ちょ', 'な', 'に', 'ぬ', 'ね', 'の', 'にゃ', 'にゅ', 'にょ', 'は', 'ひ', 'ふ', 'へ', 'ほ', 'ひゃ', 'ひゅ', 'ひょ', 'ま', 'み', 'む', 'め', 'も', 'みゃ', 'みゅ', 'みょ', 'や', 'ゆ', 'よ', 'ら', 'り', 'る', 'れ', 'ろ', 'りゃ', 'りゅ', 'りょ', 'わ', 'を', 'ん', 'が', 'ぎ', 'ぐ', 'げ', 'ご', 'ぎゃ', 'ぎゅ', 'ぎょ', 'ざ', 'じ', 'ず', 'ぜ', 'ぞ', 'じゃ', 'じゅ', 'じょ', 'だ', 'ぢ', 'づ', 'で', 'ど', 'ぢゃ', 'ぢゅ', 'ぢょ', 'ば', 'び', 'ぶ', 'べ', 'ぼ', 'びゃ', 'びゅ', 'びょ', 'ぱ', 'ぴ', 'ぷ', 'ぺ', 'ぽ', 'ぴゃ', 'ぴゅ', 'ぴょ', 'ゔ'],
    katakana: ['ア', 'イ', 'ウ', 'エ', 'オ', 'カ', 'キ', 'ク', 'ケ', 'コ', 'キャ', 'キュ', 'キョ', 'サ', 'シ', 'ス', 'セ', 'ソ', 'シャ', 'シュ', 'ショ', 'タ', 'チ', 'ツ', 'テ', 'ト', 'チャ', 'チュ', 'チョ', 'ナ', 'ニ', 'ヌ', 'ネ', 'ノ', 'ニャ', 'ニュ', 'ニョ', 'ハ', 'ヒ', 'フ', 'ヘ', 'ホ', 'ヒャ', 'ヒュ', 'ヒョ', 'マ', 'ミ', 'ム', 'メ', 'モ', 'ミャ', 'ミュ', 'ミョ', 'ヤ', 'ユ', 'ヨ', 'ラ', 'リ', 'ル', 'レ', 'ロ', 'リャ', 'リュ', 'リョ', 'ワ', 'ヲ', 'ン', 'ガ', 'ギ', 'グ', 'ゲ', 'ゴ', 'ギャ', 'ギュ', 'ギョ', 'ザ', 'ジ', 'ズ', 'ゼ', 'ゾ', 'ジャ', 'ジュ', 'ジョ', 'ダ', 'ヂ', 'ヅ', 'デ', 'ド', 'ヂャ', 'ヂュ', 'ヂョ', 'バ', 'ビ', 'ブ', 'ベ', 'ボ', 'ビャ', 'ビュ', 'ビョ', 'パ', 'ピ', 'プ', 'ぺ', 'ポ', 'ピャ', 'ピュ', 'ピョ', 'ヴ'],
    romaji: ['a', 'i', 'u', 'e', 'o', 'ka', 'ki', 'ku', 'ke', 'ko', 'kya', 'kyu', 'kyo', 'sa', 'shi', 'su', 'se', 'so', 'sha', 'shu', 'sho', 'ta', 'chi', 'tsu', 'te', 'to', 'cha', 'chu', 'cho', 'na', 'ni', 'nu', 'ne', 'no', 'nya', 'nyu', 'nyo', 'ha', 'hi', 'fu', 'he', 'ho', 'hya', 'hyu', 'hyo', 'ma', 'mi', 'mu', 'me', 'mo', 'mya', 'myu', 'myo', 'ya', 'yu', 'yo', 'ra', 'ri', 'ru', 're', 'ro', 'rya', 'ryu', 'ryo', 'wa', 'wo', 'n', 'ga', 'gi', 'gu', 'ge', 'go', 'gya', 'gyu', 'gyo', 'za', 'ji', 'zu', 'ze', 'zo', 'ja', 'ju', 'jo', 'da', 'ji', 'zu', 'de', 'do', 'ja', 'ju', 'jo', 'ba', 'bi', 'bu', 'be', 'bo', 'bya', 'byu', 'byo', 'pa', 'pi', 'pu', 'pe', 'po', 'pya', 'pyu', 'pyo', 'vu'],
} as IAlphabesDefinition;
console.log('alphabesDefinition creation')

export default alphabesDefinition;