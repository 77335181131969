import React, { useState, useRef, useEffect } from 'react';
import Stack from '@mui/material/Stack';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import Button from '@mui/material/Button';
import ButtonGroup from '@mui/material/ButtonGroup';
import VolumeUpIcon from '@mui/icons-material/VolumeUp';
import CancelIcon from '@mui/icons-material/Cancel';
import HelpIcon from '@mui/icons-material/Help';

export interface ToolbarProps {
    isPhone: boolean;
    isDisabled: boolean;
    textToSpeak: string;
    speakerCode: string;
    onHelp: () => void;
    onSkip: () => void;
    onCancel: () => void;
}

export const Toolbar = (props: ToolbarProps) => {
    console.log('Toolbar rendering')
    const [speakPending, setSpeakPending] = useState(false);
    const mounted = useRef(false);
    const helpText = props.isPhone ? '' : 'Help!';
    const skipText = props.isPhone ? '' : 'Skip';
    const readMeText = props.isPhone ? '' : 'Read me';
    const cancelText = props.isPhone ? '' : 'Cancel';
    
    const speakEnd = () => {
        if (mounted.current) setSpeakPending(false);
    }

    const speak = (e: any) => {
        e.stopPropagation();
        const wordToSpeak = new SpeechSynthesisUtterance(props.textToSpeak);
        wordToSpeak.lang = props.speakerCode;
        wordToSpeak.onend = speakEnd;
        setSpeakPending(true);
        window.speechSynthesis.speak(wordToSpeak);
        return false;
    }
    
    useEffect(() => {
        mounted.current = true;
        return () => {
            mounted.current = false;
        }; 
    }, []);

    const buttons = <React.Fragment>
            <Button variant="contained" disabled={props.isDisabled} onClick={props.onHelp} endIcon={<HelpIcon />}>{helpText}</Button>
            <Button variant="contained" disabled={props.isDisabled} onClick={props.onSkip} endIcon={<NavigateNextIcon />}>{skipText}</Button>
            <Button variant="contained" disabled={speakPending} onClick={speak} endIcon={<VolumeUpIcon />}>{readMeText}</Button>
            <Button variant="outlined" onClick={props.onCancel} endIcon={<CancelIcon />}>{cancelText}</Button>  
        </React.Fragment>;

    return (
        <React.Fragment>
            { props.isPhone && <ButtonGroup variant="contained" aria-label="outlined primary button group" className='alphabet-test-buttons' >   
                {buttons}
            </ButtonGroup> }
            {!props.isPhone && <Stack direction={{ xs: 'column', sm: 'row' }} spacing={{ xs: 1, sm: 2 }} className='alphabet-test-buttons' > 
                {buttons}              
            </Stack>}
        </React.Fragment>
    );
};