import React from 'react';
import Button from '@mui/material/Button';
import { Flags } from "../Common/Flags";

export interface ListItemProps {
    plan: any,
    currentLanguage: string
    onSelect: (plan: any) => () => void,
    onDeleteClick: (plan: any) => () => void,
}

export const ListItem = React.memo((props: ListItemProps) => {
    console.log('ListItem rendering')
    const isSelected = props.plan.language === props.currentLanguage;

    return (
        <tr className={`plan-row ${isSelected ? "selected" : ""}`} key={props.plan.language} onClick={props.onSelect(props.plan)}>
            <td>                                 
                <Flags language={""} flagSource={props.plan.flagSource} flagTarget={props.plan.flagTarget}></Flags>
            </td>
            <td>
                {props.plan.language.split('/')[0]}
            </td>
            <td>
                {props.plan.language.split('/')[1]}
            </td>
            <td>
                <Button variant="outlined" onClick={props.onDeleteClick(props.plan)}>
                    Delete
                </Button>                                
            </td>
    </tr>
    );
});