import React, { useState } from 'react';
import reactCSS from 'reactcss'
import { GithubPicker, SketchPicker  } from 'react-color'
import useMediaQuery from '@mui/material/useMediaQuery';
import './ColorPicker.css';

export interface ColorPickerProps {
    onChange: (color: string) => void,
    color: string,
    type: string,
    fullWidth: boolean
}
export const ColorPicker = React.memo((props: ColorPickerProps) => {
    console.log('ColorPicker rendering')
    const [display, setDisplay] = useState(false);
    const matches = useMediaQuery('(min-width:768px)');

    const handleClick = (e) => {
        e.stopPropagation();
        setDisplay(!display);
    };

    const handleClose = (e) => {
        e.stopPropagation();
        setDisplay(false);
    };

    const handleChange = (color, e) => {
        e.stopPropagation();
        props.onChange(color.hex);
        if (props.type === "github") {
            setDisplay(false);
        }
    };
    const styles = reactCSS({
        'default': {
            color: {
                width: props.fullWidth ? '100%' : '50%',
                height: '10px',
                background: `${props.color}`,
                cursor: 'pointer',
            },
            popover: {
                position: 'aboslute',
                zIndex: '2',
                top: !matches && props.type === "sketch" ? '-150px' : '15px'
            },
            cover: {
                display: 'block',
                position: 'fixed',
                top: '0px',
                right: '0px',
                bottom: '0px',
                left: '0px',
                background: '#6c757d26'
            }
        }
    });

    return (
        <React.Fragment>
            <div style={{position: 'relative'}}>
                <div style={styles.color} onClick={handleClick} />
                {display && <div className='colorpicker-popover' style={styles.popover}>
                    <div style={styles.cover} onClick={handleClose} />
                    {props.type === "github" && <GithubPicker color={props.color} onChange={handleChange} /> }
                    {props.type === "sketch" && <SketchPicker color={props.color} disableAlpha={true} onChange={handleChange} /> }
                </div>}
            </div>
        </React.Fragment>
    );
});