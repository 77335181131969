import React, { useState } from 'react';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import SwapVertIcon from '@mui/icons-material/SwapVert';
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import Button from '@mui/material/Button';
import Slider from "@mui/material/Slider";

export interface IAlphabet {
    name: string;
    keyboard: string;
}

export interface TestSettingsProps {
    alphabets: IAlphabet[],
    source: string,
    target: string,
    onStart: (sourceLanguage: string, targetLanguage: string, lettersCount: number) => void,
}

export const TestSettings = (props: TestSettingsProps) => {
    console.log('TestSettings rendering')
    const isAlphabetValid = props.alphabets.length > 1;
    const [sourceLanguage, setSourceLanguage] = useState(isAlphabetValid ? !!props.source ? props.source : props.alphabets[0].keyboard : '');
    const [targetLanguage, setTargetLanguage] = useState(isAlphabetValid ? !!props.target ? props.target : props.alphabets[1].keyboard : '');
    const [lettersCount, setLettersCount] = useState(20);
    if (props.alphabets.length < 2) return (<div>Incorrect alphabets settings</div>);

    const handleChangeTarget = (event) => {
        setTargetLanguage(event.target.value);
    };
    
    const handleChangeSource = (event) => {
        setSourceLanguage(event.target.value);
    };
    
    const handleStart = () => {
        props.onStart(sourceLanguage, targetLanguage, lettersCount);
    };

    const handleSwapAlphabets = () => {
        const target = targetLanguage;
        setTargetLanguage(sourceLanguage);
        setSourceLanguage(target);
    };

    const isValid = () => {
        return !!sourceLanguage && !!targetLanguage && lettersCount > 0;
    }

    const marks = [
        {
            value: 5,
            label: 5,
        },
        {
            value: 20,
            label: 20,
        },
        {
            value: 35,
            label: 35,
        },
        {
            value: 50,
            label: 50,
        },
        {
            value: 65,
            label: 65,
        },
        {
            value: 80,
            label: 80,
        },
    ];

    return (
        <div className='alphabet-settings'> 
            <FormControl fullWidth>
                <InputLabel id="demo-simple-select-label">Source alphabet</InputLabel>
                <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={sourceLanguage}
                    label="Source alphabet"
                    onChange={handleChangeSource}>
                        {props.alphabets.filter(country => country.keyboard !== targetLanguage).map((country, i) =>
                            <MenuItem key={country.keyboard} value={country.keyboard}>{country.name}</MenuItem>
                        )}
                </Select>
            </FormControl>                
            <IconButton color="primary" onClick={handleSwapAlphabets}>
                <SwapVertIcon />
            </IconButton>
            <FormControl fullWidth>
                <InputLabel id="demo-simple-select-label">Target alphabet</InputLabel>
                <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={targetLanguage}
                    label="Target alphabet"
                    onChange={handleChangeTarget}>
                        {props.alphabets.filter(country => country.keyboard !== sourceLanguage).map((country, i) =>
                            <MenuItem key={country.keyboard} value={country.keyboard}>{country.name}</MenuItem>
                        )}
                </Select>
            </FormControl>
            
            <Typography id="slider">How many letters do you want to translate?</Typography>
            <Slider
                step={1}
                min={5}
                max={80}
                aria-labelledby="slider"
                valueLabelDisplay="auto"
                marks={marks}
                value={lettersCount}
                onChange={(e: Event, v: number | number[]) => setLettersCount(v as number)} />
                
            <Button disabled={!isValid()} variant="contained" onClick={handleStart}>Start</Button>
        </div>
    )
};