import React from 'react';
import Divider from '@mui/material/Divider';
import Tooltip from '@mui/material/Tooltip';

export interface IItem {
    translation: string | undefined,
    text: string | undefined,
}

export interface InlineListWithTooltipsProps {
    items: IItem[],
    title: string,
}
export const InlineListWithTooltips = React.memo((props: InlineListWithTooltipsProps) => {
    console.log('InlineListWithTooltips rendering')
    const isNotLastItem = (index: number) : boolean => index + 1 < props.items.length;

    return (
        <div>
            {props.title && <Divider>{props.title}</Divider>}
            {props.items.map((item, i) =>
                <React.Fragment key={i}>
                    {item.translation && <Tooltip title={item.translation}>
                        <span>{item.text}{isNotLastItem(i) && <React.Fragment>,&nbsp;</React.Fragment>}</span>
                    </Tooltip>}
                    {!item.translation && 
                        <span>{item.text}{isNotLastItem(i) && <React.Fragment>,&nbsp;</React.Fragment>}</span>}
                </React.Fragment>
            )}
        </div>
    );
});