import React, { useState, useEffect, useContext, useRef, useCallback } from 'react';
import FormControl from '@mui/material/FormControl';
import { LanguageContext } from "../LanguageContext";
import { MultiScriptTextInput } from "../Common/MultiScriptTextInput";
import { useMediaQuery } from '@mui/material';
import { Container } from 'reactstrap';
import { IAlphabet, TestSettings } from './TestSettings';
import alphabesDefinition from './AlphabetsDefinition';
import './AlphabetTest.css';
import { Toolbar } from './Toolbar';
import { LettersItem, ILetter } from './LettersItem';

export const AlphabetTest = () => {
    console.log('AlphabetTest rendering')
    const [sourceLanguage, setSourceLanguage] = useState('');
    const [targetLanguage, setTargetLanguage] = useState('');
    const [speakLanguage, setSpeakLanguage] = useState('');
    const [testMode, setTestMode] = useState(false);
    const [isJapanese, setIsJapanese] = useState(false);
    const [sourceText, setSourceText] = useState([] as ILetter[]);
    const [textToSpeak, setTextToSpeak] = useState('');
    const [testedLetterCount, setTestedLetterCount] = useState(0);
    const [letterIndex, setLetterIndex] = useState(0);
    const [result, setResult] = useState('');
    const { languageSource, speakerCode } = useContext(LanguageContext);
    const inputRef = useRef<any>(null);
    const isPhone = useMediaQuery('(max-width:575px)');
    const [aplphabets, setAlphabets] = useState([] as IAlphabet[]);

    useEffect(() => {
        if (languageSource === 'ja') {
            setAlphabets([{name: 'Hiragana', keyboard: 'japanese'}, {name: 'Romaji', keyboard: 'romaji'}, {name: 'Katakana', keyboard: 'katakana'}]);
            setSpeakLanguage('japanese');
            setIsJapanese(true);
        } else if (languageSource === 'el') {            
            setAlphabets([{name: 'Greek', keyboard: 'greek'}, {name: 'Latin', keyboard: 'english'}]);
            setSpeakLanguage('greek');
            setIsJapanese(false);
        }
        if (testMode) handleCancel();
    }, [languageSource]);

    const handleStart = useCallback((source: string, target: string, letters: number) => {
        setTargetLanguage(target);
        setSourceLanguage(source);
        const random = randomText(source, target, letters);
        setSourceText(random);
        const toSpeak = random.map(x => x.toSpeak).join();
        setTextToSpeak(toSpeak)
        setTestMode(true);
    }, [aplphabets])

    const handleCancel = () => {
        setSourceText([] as ILetter[]);
        setTestMode(false);
        setTestedLetterCount(0);
        setLetterIndex(0);
        setResult('');
        window.speechSynthesis.cancel();
    };

    const onEnter = () => { };
    const onLetterClick = (e: any, letter: ILetter, index: number) => {
        e.preventDefault();
        if (!letter.visible) {
            setLetterIndex(index);
            setResult('');
            if(sourceLanguage !== 'romaji')
                inputRef.current.focus();
        }
     };

    const defaultEquals = (val1: string, val2: string): boolean => val1.normalize('NFD') === val2.normalize('NFD');
    const japaneseEquals = (val1: string, val2: string): boolean => val1.localeCompare(val2, 'ja-JP', { sensitivity: 'accent' }) === 0;

    const handleChange = (newValue: string) => {
        setResult(newValue);
        if (letterIndex <= sourceText.length && newValue.length <= 5) {
            const testRes = isJapanese
                ? japaneseEquals(sourceText[letterIndex].translation, newValue)
                : defaultEquals(sourceText[letterIndex].translation, newValue);
            if (testRes) {
                const newLetters = [...sourceText];
                const newLetter = {
                    ...sourceText[letterIndex],
                    result: true,
                    visible: true
                };
                newLetters.splice(letterIndex, 1, newLetter);
                setSourceText(newLetters);
                setTestedLetterCount(curr => curr + 1);              
                moveNext();
            }
        }
      };

    const handleHelp = () => {
        if (letterIndex <= sourceText.length) {
            const newLetters = [...sourceText];
            const newLetter = {
                ...sourceText[letterIndex],
                result: false,
                visible: true
            };
            newLetters.splice(letterIndex, 1, newLetter);
            setSourceText(newLetters);
            setTestedLetterCount(curr => curr + 1);
            moveNext();
        }
    };

    const handleSkip = () => {
        moveNext();
    };

    const moveNext = () => {    
        if (testedLetterCount > sourceText.length) return;

        let nextLetter: ILetter | null = null;
        let nextIndex = letterIndex;
        let i = 0;
        while (!nextLetter || nextLetter.visible) {
            nextIndex += 1;
            if (nextIndex >= sourceText.length)
                nextIndex = 0;

            nextLetter = sourceText[nextIndex];
            i++;
            if (i > sourceText.length + 1) 
                return;
        }
        setResult('');
        setLetterIndex(nextIndex);
        if(sourceLanguage !== 'romaji')
            inputRef.current.focus();
    }

    const isTestFinished = () : boolean => {
        return testedLetterCount >= sourceText.length;
    }

    const randomText = (source: string, target: string, letters: number) : ILetter[] => {
        let result = [] as ILetter[];        
        const charactersLength = alphabesDefinition[source].length;
    
        for ( var i = 0; i < letters; i++ ) {
            let index = Math.floor(Math.random() * charactersLength);
            result.push({
                visible: false,
                value: alphabesDefinition[source][index],
                translation: alphabesDefinition[target][index],
                toSpeak: alphabesDefinition[speakLanguage][index],
                result: false
            } as ILetter);
       }
       return result;
    }
    
    return (
        <Container>
            <div className='alphabet-header'>
                <h3>Alphabet</h3>
                { testMode && isPhone && 
                    <Toolbar isPhone={true} isDisabled={isTestFinished()} onCancel={handleCancel} onHelp={handleHelp} onSkip={handleSkip} speakerCode={speakerCode} textToSpeak={textToSpeak}  /> 
                }
            </div>
            { aplphabets.length > 0 && <div>     
                { !testMode && <TestSettings source={sourceLanguage} target={targetLanguage} alphabets={aplphabets} onStart={handleStart} />}
                { testMode && <div>
                    <div className={"letters"}>
                        {sourceText.map((letter, i) =>
                            <div key={i} onClick={(e) => onLetterClick(e, letter, i)}>
                                <LettersItem active={i === letterIndex} letter={letter} />
                            </div>
                        )}
                    </div>
                </div> }
                {sourceText && testMode && <div className='alphabet-test'>
                    <FormControl fullWidth>
                        <MultiScriptTextInput 
                            onEnter={onEnter}
                            allowEmpty={false}
                            multiInputsMode={false}
                            onChange={handleChange} 
                            value={result} 
                            inputRef={inputRef}
                            keyboard={targetLanguage} 
                            disableKeyboardFocus={isPhone}
                            label="Translate" />
                    </FormControl>    
                    {!isPhone && <Toolbar isPhone={false} isDisabled={isTestFinished()} onCancel={handleCancel} onHelp={handleHelp} onSkip={handleSkip} speakerCode={speakerCode} textToSpeak={textToSpeak} /> }
                </div> }
            </div>}
        </Container>
    );
};