import React from 'react';
import { CircleFill } from 'react-bootstrap-icons';

export interface HistoryProps {
    history: number[]
}

export const History = (props: HistoryProps) => {
    console.log('History rendering')
    return (
        <React.Fragment>
            {props.history.map((h, i) =>
                <React.Fragment key={i}>
                    {h > 0 && <span className="history-box-item"><CircleFill size={8} color={"green"}></CircleFill></span>}
                    {h < 0 && <span className="history-box-item"><CircleFill size={8} color={"red"}></CircleFill></span>}
                </React.Fragment>
            )}
        </React.Fragment>
    );
};