import 'bootstrap/dist/css/bootstrap.css';
import * as React from 'react';
import * as ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import App from './App';
import registerServiceWorker from './registerServiceWorker';
import { MsalProvider } from "@azure/msal-react";
import { PublicClientApplication, EventType } from "@azure/msal-browser";

import { LogLevel } from "@azure/msal-browser";
const baseUrl = document.getElementsByTagName('base')[0].getAttribute('href') as string;
const rootElement = document.getElementById('root');
const ua = window.navigator.userAgent;
const msie = ua.indexOf("MSIE ");
const msie11 = ua.indexOf("Trident/");
const msedge = ua.indexOf("Edge/");
const firefox = ua.indexOf("Firefox");
const isIE = msie > 0 || msie11 > 0;
const isEdge = msedge > 0;
const isFirefox = firefox > 0; // Only needed if you need to support the redirect flow in Firefox incognito

const b2cPolicies = {
    names: {
        signUpSignIn: process.env.REACT_APP_SignUpSignIn as string,
        forgotPassword: process.env.REACT_APP_ForgotPassword as string,
    },
    authorities: {
        signUpSignIn: {
            authority: process.env.REACT_APP_SignUpSignInAuthority as string,
        },
        forgotPassword: {
            authority: process.env.REACT_APP_ForgotPasswordAuthority as string,
        }
    },
    authorityDomain: process.env.REACT_APP_AuthorityDomain as string
}

const apiConfig = {
    b2cScopes: [process.env.REACT_APP_B2cScopes as string],
    webApi: process.env.REACT_APP_API as string
};

// Config object to be passed to Msal on creation
export const msalConfig = {
    auth: {
        clientId: process.env.REACT_APP_ClientId as string,
        authority: b2cPolicies.authorities.signUpSignIn.authority as string,
        knownAuthorities: [b2cPolicies.authorityDomain as string],
        redirectUri: process.env.REACT_APP_RedirectUri as string,
    },
    cache: {
        storeAuthStateInCookie: isIE || isEdge || isFirefox,
        cacheLocation: "localStorage",
    },
    system: {
        loggerOptions: {
            loggerCallback: (level: any, message: any, containsPii: any) => {
                if (containsPii) {
                    return;
                }
                switch (level) {
                    case LogLevel.Error:
                        console.error(message);
                        return;
                    case LogLevel.Info:
                        return;
                    case LogLevel.Verbose:
                        return;
                    case LogLevel.Warning:
                        console.warn(message);
                        return;
                    default:
                        return;
                }
            }
        }
    }
};

const loginRequest = {
    scopes: ["openid", ...apiConfig.b2cScopes],
};
export const graphConfig = {
    graphMeEndpoint: "https://graph.microsoft-ppe.com/v1.0/me"
};

const pca = new PublicClientApplication(msalConfig);
const accounts = pca.getAllAccounts();
if (accounts.length > 0) {
    pca.setActiveAccount(accounts[0]);
}
pca.addEventCallback((event: any) => {
    if (event.eventType === EventType.LOGIN_SUCCESS && event.payload.account) {
        const account = event.payload.account;
        pca.setActiveAccount(account);
    }
    else if (event.eventType === EventType.ACQUIRE_TOKEN_FAILURE) {
        console.log('ACQUIRE_TOKEN_FAILURE')
        acquireAccessToken(pca).then(resp => console.log('acquireAccessToken in addEventCallback finished'));
    }
});
ReactDOM.render(
    <MsalProvider instance={pca}>
      <BrowserRouter basename={baseUrl}>
        <App />
        </BrowserRouter>
    </MsalProvider>,
  rootElement);

registerServiceWorker();

export const acquireAccessToken = async (pca: PublicClientApplication) => {
    const activeAccount = pca.getActiveAccount(); 
    if (activeAccount) {
        const accounts = pca.getAllAccounts();
        if (accounts.length === 0) {
            /*
            * User is not signed in. Throw error or wait for user to login.
            * Do not attempt to log a user in outside of the context of MsalProvider
            */
            console.error('error');
            return null;
        }
        const request = {
            scopes: loginRequest.scopes,
            account: activeAccount || accounts[0]
        };
        try
        {
            const authResult = await pca.acquireTokenSilent(request);
            // throw new Error();
            return authResult;
        }
        catch(error)
        {
            await pca.acquireTokenRedirect(request);
        }
    }
    return null;
};

export const getToken = async () : Promise<string> => {
    var result = await acquireAccessToken(pca);
    return result?.accessToken ?? '';
}

export const getBaseUrl = () => {
    return process.env.REACT_APP_API + '/';
}

export const getUserInfo = async () => {
    var result = await acquireAccessToken(pca);
    return result;
}

export const logout = async () => {
    await pca.logoutRedirect();
}