/**
 * Layout: Japanese
 */
export default {
    layout: {
        default: [
            "\u306c \u3075 \u3042 \u3046 \u3048 \u304a \u3084 \u3086 \u3088 \u308f \u307b \u3078 \u30fc {bksp}",
            "{tab} \u305f \u3066 \u3044 \u3059 \u304b \u3093 \u306a \u306b \u3089 \u305b \u3099 \u309a \u3080",
            "{lock} \u3061 \u3068 \u3057 \u306f \u304D \u304f \u307e \u306e \u308a \u308c \u3051 {enter}",
            "{shift} \u3064 \u3055 \u305d \u3072 \u3053 \u307f \u3082 \u306d \u308b \u3081 \u308d",
            ".com @ {space}",
        ],
        shift: [
            "1 2 3 4 5 6 7 8 9 \u3092 - ^ \u00a5 {bksp}",
            "{tab} \u305f \u3066 \u3043 \u3059 \u304b \u3093 \u306a \u306b \u3089 \u305b \u300c \u300d \u3080",
            "{lock} \u3061 \u3068 \u3057 \u306f \u304D \u304f \u307e \u306e \u308a \u308c \u3051 {enter}",
            "{shift} \u3063 \u3055 \u305d \u3072 \u3053 \u307f \u3082 \u3001 \u3002 \u30fb _",
            ".com @ {space}",
        ],
    },
    layoutCandidates: {
        "\u306c": "\u306c 1 !",
        "\u3075": "\u3075 2 \"",
        "\u3042": "\u3042 \u3041 3 #",
        "\u3046": "\u3046 \u3045 4 $",
        "\u3048": "\u3048 \u3047 5 %",
        "\u304a": "\u304a \u3049 6 &",
        "\u3084": "\u3084 \u3083 7 '",
        "\u3086": "\u3086 \u3085 8 (",
        "\u3088": "\u3088 \u3087 9 )",
        "\u308f": "\u308f \u3092 0",
        "\u307b": "\u307b - =",
        "\u3078": "\u3078 ^ ~",
        "\u30fc": "\u30fc \u00a5 ^ |",
    },
};
