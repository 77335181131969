import React, { useContext } from 'react';
import { LanguageContext } from "../LanguageContext";
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';

export interface PartOfSpeachProps {    
    grammaticalFeatures: any[];
    onChange: (partOfSpeach: string, grammaticalFeatures: any[]) => void;
    partOfSpeech: string;
    word: string;
    editMode: boolean;
}

export const PartOfSpeach = (props: PartOfSpeachProps) => {
    console.log('PartOfSpeach rendering')
    const { languageSourceName, country } = useContext(LanguageContext);
    const gender = props.grammaticalFeatures.find(feature => feature.type.toLowerCase() === "gender") ?? { id: ""}
    const genderDropdown = country.nounGenders && Object.entries(country.nounGenders).map(([key, value]) =>     
        <MenuItem key={key} value={key}>{key}</MenuItem>        
    )

    const handlePartOfSpeechChange = (e: any) => {
        const newPartOfSpeach = e.target.value;
        const newFeatures = newPartOfSpeach?.toLowerCase() === "noun" 
            ? props.grammaticalFeatures
            : genderChange('')
        props.onChange(e.target.value, newFeatures);
    }
    const handleGenderChange = (e: any) => {
        props.onChange(props.partOfSpeech, genderChange(e.target.value));
    }

    const genderChange = (value: string) : any[] => {
        const exist = props.grammaticalFeatures.some(feature => feature.type.toLowerCase() === "gender")
        const newValue = exist 
            ? props.grammaticalFeatures.map(feature => {
                    if (feature.type.toLowerCase() === "gender") {
                        return { ...feature, id: value };
                    } else {
                        return feature;
                    }
                })
            : [...props.grammaticalFeatures, { type: "Gender", id: value }]
        return newValue;
    }

    const getConjugationLink = () => {
        return "https://www.verbix.com/webverbix/" + languageSourceName + "/" + props.word + ".html";
    }

    return (
        <React.Fragment>
            { !props.editMode && <div>{props.partOfSpeech}</div> }
            {props.editMode && <FormControl fullWidth sx={{ marginTop: 3 }}>
                <InputLabel id="partOfSpeech-label">Part of speech</InputLabel>
                    <Select
                    labelId="partOfSpeech-label"
                    id="partOfSpeech-select"
                    value={props.partOfSpeech}
                    label="Part of speech"
                    onChange={handlePartOfSpeechChange}
                    >
                        <MenuItem value={"Other"}>Other</MenuItem>
                        <MenuItem value={"Adjective"}>Adjective</MenuItem>
                        <MenuItem value={"Adverb"}>Adverb</MenuItem>
                        <MenuItem value={"Conjunction"}>Conjunction</MenuItem>
                        <MenuItem value={"Determiner"}>Determiner</MenuItem>
                        <MenuItem value={"Verb"}>Verb</MenuItem>
                        <MenuItem value={"Noun"}>Noun</MenuItem>
                        <MenuItem value={"Preposition"}>Preposition</MenuItem>
                        <MenuItem value={"Pronoun"}>Pronoun</MenuItem>
                    </Select>
                </FormControl>}
            {props.editMode && props.partOfSpeech?.toLowerCase() === "noun" && !!genderDropdown &&<FormControl fullWidth sx={{ marginTop: 3 }}>
                <InputLabel id="gender-label">Gender</InputLabel>
                    <Select
                    labelId="gender-label"
                    id="gender-select"
                    value={gender.id}
                    label="Gender"
                    onChange={handleGenderChange}
                    >
                        {genderDropdown}
                    </Select>
                </FormControl>}
            {props.partOfSpeech?.toLowerCase() === "verb" && <div>
                <a target="_blank" href={getConjugationLink()}>Conjugation</a>
            </div>}
        </React.Fragment>
    );
};