import React, { useState } from 'react';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Button from '@mui/material/Button';
import ListItemText from '@mui/material/ListItemText';
import ListItemIcon from '@mui/material/ListItemIcon';
import ImageSearchIcon from '@mui/icons-material/ImageSearch';
import TextSnippetIcon from '@mui/icons-material/TextSnippet';
import BrushIcon from '@mui/icons-material/Brush';
import DeleteIcon from '@mui/icons-material/Delete';

export interface FlashcardMenuProps {
    toggleSearchPhotoMode: () => void;
    toggleWhiteboardMode: () => void;
    toggleShowNotes: () => void;
    onRemove: () => void;
}

export const FlashcardMenu = React.memo((props: FlashcardMenuProps) => {
    console.log('FlashcardMenu rendering')
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);
    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    const toggleSearchPhotoMode = (e: any) => {
        e.stopPropagation();
        props.toggleSearchPhotoMode();
        handleClose();
        return false;
    }

    const toggleWhiteboardMode = (e: any) => {
        e.stopPropagation();
        props.toggleWhiteboardMode();
        handleClose();
        return false;
    }

    const toggleShowNotes = (e: any) => {
        e.stopPropagation();
        props.toggleShowNotes();
        handleClose();
        return false;
    }

    const remove = (e: any) => {
        e.stopPropagation();
        props.onRemove();
        handleClose();
        return false;
    }

    return (
        <div className="flashcard-menu">
            <Button
                sx={{ position: 'absolute', right: 0, color: "#259dd5", border: '1px solid #259dd5 !important' }}
                aria-label="more"
                id="long-button"
                aria-controls="long-menu"
                aria-expanded={open ? 'true' : undefined}
                aria-haspopup="true"
                onClick={handleClick}
            >
                <MoreVertIcon />
            </Button>
            <Menu
                id="basic-menu"
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                MenuListProps={{
                    'aria-labelledby': 'basic-button',
                }}>
                <MenuItem onClick={toggleShowNotes}>
                    <ListItemIcon>
                        <TextSnippetIcon fontSize="small" />
                    </ListItemIcon>
                    <ListItemText>Add notes</ListItemText>
                </MenuItem>
                <MenuItem onClick={toggleSearchPhotoMode}>
                    <ListItemIcon>
                        <ImageSearchIcon fontSize="small" />
                    </ListItemIcon>
                    <ListItemText>Add image</ListItemText>
                </MenuItem>
                <MenuItem onClick={toggleWhiteboardMode}>
                    <ListItemIcon>
                        <BrushIcon fontSize="small" />
                    </ListItemIcon>
                    <ListItemText>Add whiteboard</ListItemText>
                </MenuItem>
                <MenuItem onClick={remove}>
                    <ListItemIcon>
                        <DeleteIcon fontSize="small" />
                    </ListItemIcon>
                    <ListItemText>Remove</ListItemText>
                </MenuItem>
            </Menu>
        </div>
    );
});