import React from 'react';

export interface GrammaticalFeatureProps {
    grammaticalFeatures: any[],
    partOfSpeech: string,
    languageSource: string
}

export const GrammaticalFeature = (props: GrammaticalFeatureProps) => {
    console.log('GrammaticalFeature rendering')
    return (
        <React.Fragment>
            {props.grammaticalFeatures.filter(x => x.type.toLowerCase() === "gender").map(feature => <div key={feature.id}>Gender: {feature.id}</div>)}
        </React.Fragment>
    );
};