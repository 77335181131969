/**
 * Layout: Japanese
 */
export default {
    layout: {
        default: [
            "\u30CC \u30D5 \u30A2 \u30A6 \u30A8 \u30AA \u30E4 \u30E6 \u30E8 \u30EF \u30DB \u30D8 \u30fc {bksp}",
            "{tab} \u30BF \u30C6 \u30A4 \u30B9 \u30AB \u30F3 \u30CA \u30CB \u30E9 \u30BB \u3099 \u309a \u30E0",
            "{lock} \u30C1 \u30C8 \u30B7 \u30CF \u30AD \u30AF \u30DE \u30CE \u30EA \u30EC \u30B1 {enter}",
            "{shift} \u30C4 \u30B5 \u30BD \u30D2 \u30B3 \u30DF \u30E2 \u30CD \u30EB \u30E1 \u30ED",
            ".com @ {space}",
        ],
        shift: [
            "1 2 3 4 5 6 7 8 9 \u30F2 - ^ \u00a5 {bksp}",
            "{tab} \u30BF \u30C6 \u30A3 \u30B9 \u30AB \u30F3 \u30CA \u30CB \u30E9 \u30BB \u300c \u300d \u30E0",
            "{lock} \u30C1 \u30C8 \u30B7 \u30CF \u30AD \u30AF \u30DE \u30CE \u30EA \u30EC \u30B1 {enter}",
            "{shift} \u30C3 \u30B5 \u30BD \u30D2 \u30B3 \u30DF \u30E2 \u3001 \u3002 \u30fb _",
            ".com @ {space}",
        ],
    },
    layoutCandidates: {
        "\u30CC": "\u30CC 1 !",
        "\u30D5": "\u30D5 2 \"",
        "\u30A2": "\u30A2 \u30A1 3 #",
        "\u30A6": "\u30A6 \u30A5 4 $",
        "\u30A8": "\u30A8 \u30A7 5 %",
        "\u30AA": "\u30AA \u30A9 6 &",
        "\u30E4": "\u30E4 \u30E3 7 '",
        "\u30E6": "\u30E6 \u30E5 8 (",
        "\u30E8": "\u30E8 \u30E7 9 )",
        "\u30EF": "\u30EF \u30F2 0",
        "\u30DB": "\u30DB - =",
        "\u30D8": "\u30D8 ^ ~",
        "\u30fc": "\u30fc \u00a5 ^ |",
    },
};
