import React, { useState, useEffect, useRef } from 'react';
import Button from '@mui/material/Button';
import { LoadingButton } from '../Common/LoadingButton';

export interface ToolbarDraftProps {
    onSave: () => Promise<any>;
    onCancel: () => void;
    canSave: boolean;
}

export const ToolbarDraft = (props: ToolbarDraftProps) => {
    console.log('ToolbarDraft rendering')
    const [pending, setPending] = useState(false);
    const mounted = useRef(false);
    const saveChanges = (e: any) => {
        e.stopPropagation();
        setPending(true);
        return props.onSave()
            .finally(() => {
                if (mounted.current) setPending(false);
            });
    }    

    const cancelChanges = (e: any) => {
        e.stopPropagation();
        props.onCancel();
        return false;
    }

    useEffect(() => {
        mounted.current = true;
        return () => {
            mounted.current = false;
        }; 
    }, []);

    return (
        <React.Fragment>
            <LoadingButton
                variant="contained"
                className="toolbar-btn"
                loading={pending}
                disabled={!props.canSave || pending}
                onClick={saveChanges}>
                Save
            </LoadingButton>
            <Button
                variant="outlined"
                className="toolbar-btn"
                onClick={cancelChanges}>
                Cancel
            </Button>
        </React.Fragment>      
    );
};