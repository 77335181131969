import React, { useContext } from 'react';
import { LanguageContext } from "../LanguageContext";

export interface WordGenderProps {
    grammaticalFeatures: any[],
    partOfSpeech: string,
}

export const WordGender = (props: WordGenderProps) => {
    console.log('WordGender rendering')
    const wordGenders = props.grammaticalFeatures.filter(x => x.type.toLowerCase() === "gender");
    const { country } = useContext(LanguageContext);
     
    const resolveGender = (id: string) => {
        if (country.nounGenders) {
            return country.nounGenders[id] ?? '';
        }
        return '';
    }

    const display = props.partOfSpeech.toLowerCase() === "noun" && wordGenders.map(gender => resolveGender(gender.id)).join("/");

    return (
        <React.Fragment>
            {display}
        </React.Fragment>
    );
};

