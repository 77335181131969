import React, { useState } from 'react';
import Box from '@mui/material/Box';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import countries from '../../data/countries.json';
import dictionaries from '../../data/dictionaries.json';
import Button from '@mui/material/Button';
import { userSettingsCreateCall } from '../../api-definitions';

export interface CreateBoxProps {
    onCreated: () => void,
}

export const CreateBox = React.memo((props: CreateBoxProps) => {
    console.log('CreateBox rendering')
    const [sourceLanguage, setSourceLanguage] = useState('');
    const [targetLanguage, setTargetLanguage] = useState('');

    const handleChangeTarget = (event) => {
        setTargetLanguage(event.target.value);
    };

    const handleChangeSource = (event) => {
        setSourceLanguage(event.target.value);
    };

    const create = async () => {
        const toSendIndex = dictionaries.languageCodes.indexOf(sourceLanguage + "/" + targetLanguage);
        const lang = encodeURIComponent(dictionaries.languageCodes[toSendIndex]);
        await userSettingsCreateCall(lang)
        setSourceLanguage('');
        setTargetLanguage('');
        props.onCreated();
    }

    return (
        <div>
            <h4>Start to learn a new language!</h4>
            <Box sx={{ minWidth: 120 }}>
                <FormControl fullWidth className='source-selector'>
                    <InputLabel id="source-label">What language do you want to learn?</InputLabel>
                    <Select
                        labelId="source-label"
                        id="source-select"
                        value={sourceLanguage}
                        label="What language do you want to learn?"
                        onChange={handleChangeSource}>
                        {countries.map((country, i) =>
                            <MenuItem key={country.key} value={country.key}>{country.name}</MenuItem>
                        )}
                    </Select>
                </FormControl>
                {sourceLanguage &&
                    <FormControl fullWidth className='target-selector'>
                        <InputLabel id="target-label">In what language do you want to learn?</InputLabel>
                        <Select
                            labelId="target-label"
                            id="target-select"
                            value={targetLanguage}
                            label="What language do you want to learn?"
                            onChange={handleChangeTarget}>{countries
                            .filter(country => dictionaries.languageCodes.indexOf(sourceLanguage + "/" + country.key) > 1).map((country, i) =>
                                <MenuItem key={country.key} value={country.key}>{country.name}</MenuItem>
                            )}
                        </Select>
                    </FormControl>}
                {sourceLanguage && targetLanguage && <Button className="" variant="contained" onClick={create}>Let's start!</Button>}
            </Box>
        </div>
    );
});