import React, { useState, useLayoutEffect, MutableRefObject } from 'react';
import FormControl from '@mui/material/FormControl';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import IconButton from '@mui/material/IconButton';
import InputAdornment from '@mui/material/InputAdornment';
import KeyboardIcon from '@mui/icons-material/Keyboard';
import KeyboardWrapper from "./KeyboardWrapper";
import CancelIcon from '@mui/icons-material/Cancel';

export interface TextInputWithKeyboardProps {
    onChange: (e: any) => void,
    onEnter: () => void,
    allowEmpty: boolean,
    label: string,
    value: string
    keyboard: string;
    inputRef: MutableRefObject<any>;
    iconButton?: React.ReactNode;
    disableKeyboardFocus?: boolean;
}

export const TextInputWithKeyboard = (props: TextInputWithKeyboardProps) => {
    console.log('TextInputWithKeyboard render')
    const [showKeyboard, setShowKeyboard] = useState(false);
    const input = props.inputRef;

    const keyDownHandler = (event: React.KeyboardEvent<HTMLInputElement>) => {
        if ((event.code === "Enter" || event.keyCode === 13 || event.key === "Enter") && (props.value || props.allowEmpty)) {
            props.onEnter();
        }
    };

    const focusInput = () => {
        if (!props.disableKeyboardFocus && !!input && !!input.current)
            input.current.focus()
    }

    const toggleKeyboard = () => {
        setShowKeyboard(p => !p);
        focusInput();
    }

    const clear = () => {
        props.onChange("");
    }

    const onChangeInput = (event: any): void => {
        const input = event.target.value;
        props.onChange(input);
    };

    useLayoutEffect(() => {
        if (!props.disableKeyboardFocus) {
            setTimeout(() => {
                focusInput();
            }, 100);
        }
    }, [props.value]);

    return (
        <React.Fragment>
            {showKeyboard && <KeyboardWrapper value={props.value} keyboard={props.keyboard} onChange={props.onChange} />}
            <FormControl variant="outlined" fullWidth>
                <InputLabel htmlFor="search">{props.label}</InputLabel>
                <OutlinedInput
                    inputRef={input}
                    id="search"
                    value={props.value}
                    onChange={onChangeInput}
                    label={props.label}
                    autoComplete="off"
                    onKeyDown={keyDownHandler}
                    endAdornment={
                        <InputAdornment position="end">                            
                            {props.value && <IconButton
                                aria-label="toggle"
                                onClick={clear}
                                edge="end" >
                                <CancelIcon />
                            </IconButton>}
                            <IconButton
                                aria-label="toggle"
                                onClick={toggleKeyboard}
                                edge="end" >
                                <KeyboardIcon />
                            </IconButton>
                            {props.iconButton && props.iconButton}
                        </InputAdornment>} />
            </FormControl>
        </React.Fragment>
    );
};