import React from 'react';
import Button from '@mui/material/Button';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import { ToolbarLearnMode } from './ToolbarLearnMode';
import { ToolbarTestMode } from './ToolbarTestMode';

export interface ToolbarLearningProps {
    mode: "test" | "learn" | "edit";
    onResponse: (result: boolean) => void;
    onReverse: () => void;
    onClose: () => void;
}

export const ToolbarLearning = (props: ToolbarLearningProps) => {
    console.log('ToolbarLearning rendering')
    return (
        <React.Fragment>
            {props.mode === "edit" && <Button className="toolbar-btn" variant="outlined" onClick={props.onClose} endIcon={<NavigateNextIcon />}>
                Close
            </Button>}
            {props.mode === "learn" && <ToolbarLearnMode onResponse={props.onResponse} onReverse={props.onReverse} />}
            {props.mode === "test" && <ToolbarTestMode onResponse={props.onResponse} />}
        </React.Fragment>
    );
};