import React, { Component } from 'react';
import { Collapse, Container, Navbar, NavbarBrand, NavbarToggler, NavItem, NavLink } from 'reactstrap';
import { Link, NavLink as RRNavLink } from 'react-router-dom';
import './NavMenu.css';
import { LanguageContext } from "./LanguageContext";
import { Flags } from "./Common/Flags";
import { logout } from '../index';
import { Logo } from './Logo';

export class NavMenu extends Component {
  constructor (props) {
    super(props);

    this.toggleNavbar = this.toggleNavbar.bind(this);
    this.closeNavbar = this.closeNavbar.bind(this);
    }

    static contextType = LanguageContext;
  componentDidMount() {
      const language = this.context;

      this.setState({
          ...this.state,
          language: language
      });
  }
  public state = {
      isOpen: false,
      language: ''
  };

  toggleNavbar () {
      this.setState({
        ...this.state,
        isOpen: !this.state.isOpen
    });
  }

  closeNavbar () {
    this.setState({
      ...this.state,
      isOpen: false
  });
}

  render () {    
    const { language, flagSource, flagTarget, alphabetTest } = this.context;
    return (
      <header className="flapp">
        <Navbar className="navbar-expand-sm navbar-toggleable-sm ng-white border-bottom box-shadow mb-3" light collapseonselect="true" fixed="top">
          <Container>
            <NavbarBrand tag={Link} to="/">
              <Logo language={language} flagSource={flagSource} flagTarget={flagTarget} />
            </NavbarBrand>
            <NavbarToggler onClick={this.toggleNavbar} className="mr-2" />
            <Collapse className="d-sm-inline-flex flex-sm-row-reverse" isOpen={this.state.isOpen} navbar>
              <ul className="navbar-nav flex-grow">
                {language && <React.Fragment>
                  <NavItem className="colorfull">
                    <NavLink onClick={this.closeNavbar} tag={RRNavLink} className="text-dark" to="/add" activeClassName="active-link">Add</NavLink>
                  </NavItem>
                  <NavItem className="colorfull">
                    <NavLink onClick={this.closeNavbar} tag={RRNavLink} className="text-dark" to="/test" activeClassName="active-link">Learning</NavLink>
                  </NavItem>
                  <NavItem className="colorfull">
                      <NavLink onClick={this.closeNavbar} tag={RRNavLink} className="text-dark" to="/definitions" activeClassName="active-link">Flashcards</NavLink>
                  </NavItem>
                  {alphabetTest && <NavItem className="colorfull">
                      <NavLink onClick={this.closeNavbar} tag={RRNavLink} className="text-dark" to="/alphabet" activeClassName="active-link">Alphabet test</NavLink>
                  </NavItem> }                               
                  <NavItem className="colorfull">
                    <NavLink onClick={this.closeNavbar} tag={RRNavLink} activeClassName="active-link" className="text-dark" to="/plan">
                      <Flags language={language} flagSource={flagSource} flagTarget={flagTarget} ></Flags>
                    </NavLink>
                  </NavItem>
                </React.Fragment>}
                { !language && <NavItem className="colorfull">
                  <NavLink onClick={this.closeNavbar} tag={RRNavLink} activeClassName="active-link" className="text-dark" to="/plan">Select languages</NavLink>
                </NavItem>}
                <NavItem className="colorfull" onClick={(e) => { e.preventDefault(); logout(); return false; }}>
                  <NavLink onClick={this.closeNavbar} tag={Link} to="/" className="text-dark">Log out</NavLink>
                </NavItem>
              </ul>
            </Collapse>
          </Container>
        </Navbar>
      </header>
    );
  }
}