import React from 'react';
import { History } from '../Common/History';
import { Rating } from '../Common/Rating';
import { InlineList } from '../Common/InlineList';
import { Definition } from '../../api-models';

export interface DefinitionsListItemProps {
    definition: Definition,
    onClick: (definition: Definition) => void,
}

export const DefinitionsListItem = React.memo((props: DefinitionsListItemProps) => {
    console.log('DefinitionsListItem rendering')

    return (
        <tr key={props.definition.id} onClick={() => {props.onClick(props.definition)}} >
            <td>{props.definition.word}</td>
            <td>{props.definition.definition}</td>
            <td>
                {props.definition.translations.length > 0 && <InlineList items={props.definition.translations} title={''}/>}
            </td>
            <td>{props.definition.partOfSpeech}</td>
            <td className="center">
                <Rating rating={props.definition.rating} memoryBox={props.definition.memoryBox}></Rating>
            </td>
            <td className="nowrap"><History history={props.definition.history}></History></td>
        </tr>
    );
});