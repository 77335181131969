import React, { useState, useRef } from 'react';
import Keyboard from "react-simple-keyboard";
import "react-simple-keyboard/build/css/index.css";
import japanese from "./hiragana";
import katakana from "./katakana";
import spanish from "simple-keyboard-layouts/build/layouts/spanish";
import german from "simple-keyboard-layouts/build/layouts/german";
import english from "simple-keyboard-layouts/build/layouts/english";
import french from "simple-keyboard-layouts/build/layouts/french";
import greek from "simple-keyboard-layouts/build/layouts/greek";
import './KeyboardWrapper.css';
/*import chinese from "simple-keyboard-layouts/build/layouts/chinese";*/

interface IProps {
    onChange: (input: string) => void;
    keyboard: string;
    value: string;
}

const KeyboardWrapper = React.memo((props: IProps) => {
    console.log('KeyboardWrapper render')
    const keyboard = useRef<any>(null);
    const [layoutName, setLayoutName] = useState("default");
    const onKeyPress = (button: string) => {
        if (button === "{shift}" || button === "{lock}") {
            setLayoutName(layoutName === "default" ? "shift" : "default");
        }
    };

    setTimeout(() => {
        if (keyboard && keyboard.current) {
            keyboard.current.setInput(props.value);
        }
    }, 100);

    const keyboardResolver = () => {
        if (props.keyboard === "spanish") {
            return spanish;
        }
        else if (props.keyboard === "french") {
            return french;
        }
        else if (props.keyboard === "japanese") {
            return japanese;
        }        
        else if (props.keyboard === "katakana") {
            return katakana;
        }
        else if (props.keyboard === "german") {
            return german;
        }
        else if (props.keyboard === "greek") {
            return greek;
        }
        //else if (id === "chinese") {
        //    return chinese;
        //}
        else {
            return english;
        }
    }

    const layout = keyboardResolver();

    const onChange = (event: any): void => {
        const input = event.target.value;
        keyboard.current.setInput(props.value);
        onChange(input);
    };

    return (
        <Keyboard
            keyboardRef={r => (keyboard.current = r)}
            layoutName={layoutName}
            onChange={props.onChange}
            onKeyPress={onKeyPress}
            {...layout}
        />
    );
});

export default KeyboardWrapper;