import { Definition, UserSettings, UserTestSet } from './api-models';
import HttpCommon from './http-common';

export const distionarySearchCall = (searchWord: string, language: string) => 
    HttpCommon.get(`dictionary/search/${searchWord}`, language);

export const wordsAddCall = (language: string, toSend: any) => 
    HttpCommon.post('words/add', language, toSend);

export const wordsAddCustomCall = (language: string, definition: Definition, whiteboard: string) => {    
    const toSend = {
        id: definition.id,
        word: definition.word,
        definition: definition.definition,
        customDefinition: definition.customDefinition,
        partOfSpeech: definition.partOfSpeech,
        grammaticalFeatures: definition.grammaticalFeatures,
        notes: definition.notes,
        color: definition.color,
        whiteboard: whiteboard,
        translations: definition.translations,
        imageRef: definition.imageRef
    };
    
    return HttpCommon.post('words/addCustom', language, toSend).then(response => response.data as Definition);
}

export const wordsUpdateCall = (id: string, language: string, definition: Definition, whiteboard: string) => {    
    const toSend = {
        imageRef: definition.imageRef,
        color: definition.color,
        customDefinition: definition.customDefinition,
        notes: definition.notes,
        whiteboard: whiteboard ? JSON.stringify(whiteboard) : null
    };
    
    return HttpCommon.put(`words/update/${id}`, language, toSend)
        .then(response => response.data as Definition);
}

export const wordsDeleteCall = (id: string, language: string) => 
    HttpCommon.remove(`words/delete/${id}`, language);

export const wordsGetCall = (page: number, language: string, search: string) => 
    HttpCommon.get(`words/get?page=${page}&search=${search}`, language);

export const wordsCountCall = (language: string) => 
    HttpCommon.get('words/count', language);

export const learningTestCall = (id: string, language: string, toSend: any) => 
    HttpCommon.post(`learning/test/${id}`, language, toSend);

export const learningLearnedCall = (id: string, language: string, toSend: any) =>
    HttpCommon.post(`learning/learned/${id}`, language, toSend);

export const learningGetTestSetCall = (language: string) =>
    HttpCommon.get('learning/getTestSet', language).then(response => response.data as UserTestSet);

export const learningStartTestSetCall = (language: string, toSend: any) =>
    HttpCommon.post('learning/startTest', language, toSend);

export const searchPhotosCall = (searchPhotoText: string) => 
    HttpCommon.get(`graphics/searchPhotos/${searchPhotoText}`, "");

export const searchIconCall = (searchIconsText: string) => 
    HttpCommon.get(`graphics/searchIcons/${searchIconsText}`, '');

export const userSettingsGetCall = (language: string) => 
    HttpCommon.get('userSettings/get', language).then(response => response.data as UserSettings);

export const userSettingsGetAllCall = (language: string) => 
    HttpCommon.get('userSettings/getAll', language);

export const userSettingsUpdateCall = (language: string, dailyTestCapacity: number, reverseTestFrequency: number) => 
    HttpCommon.post('userSettings/update', language, { dailyTestCapacity: dailyTestCapacity, reverseTestFrequency: reverseTestFrequency});

export const userSettingsCreateCall = (lang: string) => 
    HttpCommon.put(`userSettings/create?language=${lang}`, '', {});

export const userSettingsDeleteCall = (lang: string) => 
    HttpCommon.remove(`userSettings/delete?language=${lang}`, '');