import React from 'react';
import Button from '@mui/material/Button';
import ThumbUpIcon from '@mui/icons-material/ThumbUp';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import ScreenRotationIcon from '@mui/icons-material/ScreenRotation';
import useMediaQuery from '@mui/material/useMediaQuery';

export interface ToolbarLearnModeProps {
    onResponse: (result: boolean) => void;
    onReverse: () => void;
}

export const ToolbarLearnMode = (props: ToolbarLearnModeProps) => {
    console.log('ToolbarLearnMode rendering')
    const matches = useMediaQuery('(min-width:768px)');
    return (
        <React.Fragment>
            {matches && <React.Fragment>
                <Button className="toolbar-btn" variant="outlined" onClick={() => { props.onResponse(false) }} endIcon={<NavigateNextIcon />}>
                    Go next
                </Button>
                <Button className="toolbar-btn" variant="contained" onClick={props.onReverse} endIcon={<ScreenRotationIcon />}>
                    Reverse
                </Button>
                <Button className="toolbar-btn" variant="contained" color="success" onClick={() => { props.onResponse(true) }} endIcon={<ThumbUpIcon />}>
                    Learned!
                </Button>
            </React.Fragment>}
            {!matches && <React.Fragment>
                <Button className="toolbar-btn" variant="outlined" onClick={() => { props.onResponse(false) }} endIcon={<NavigateNextIcon />}>
                    Next
                </Button>
                <Button className="toolbar-btn" variant="contained" onClick={props.onReverse} >
                    <ScreenRotationIcon />
                </Button>
                <Button className="toolbar-btn" variant="contained" color="success" onClick={() => { props.onResponse(true) }} >
                    <ThumbUpIcon />
                </Button>
            </React.Fragment>}
        </React.Fragment>
    );
};