import React from 'react';
import styled from 'styled-components';
import CircularProgress from '@mui/material/CircularProgress';
     
const PendingBox = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 150px;
    justify-content: center;
    `;

export const Pending = React.memo(() => { 
    console.log('Pending render') 
    return (
        <PendingBox>
            <CircularProgress disableShrink />
        </PendingBox>
    );
});