import React from 'react';
import { HandThumbsDownFill, HandThumbsUpFill } from 'react-bootstrap-icons';
import QueryBuilderIcon from '@mui/icons-material/QueryBuilder';
import MilitaryTechIcon from '@mui/icons-material/MilitaryTech';
import ThumbsUpDownIcon from '@mui/icons-material/ThumbsUpDown';

export interface RatingProps {
    rating: number,
    memoryBox: string
}

export const Rating = (props: RatingProps) => {
    console.log('Rating render') 
    const ratingSize = props.rating > 0 ? (props.rating / 2) + 8 : (-1 * props.rating / 2) + 8;
    return (
        <React.Fragment>
            {props.rating > 0 && props.memoryBox === "Retired" && <MilitaryTechIcon fontSize={"large"} color={"success"}></MilitaryTechIcon>}
            {props.rating > 0 && props.memoryBox !== "Retired" && <HandThumbsUpFill size={ratingSize} color={"green"}></HandThumbsUpFill>}
            {props.rating < 0 && <HandThumbsDownFill size={ratingSize} color={"red"}></HandThumbsDownFill>}
            {props.rating === 0 && props.memoryBox === "-1" && <QueryBuilderIcon color={"disabled"} />}
            {props.rating === 0 && props.memoryBox !== "-1" && <ThumbsUpDownIcon color={"disabled"} />}
        </React.Fragment>
    );
};