import React, { MutableRefObject, useContext } from 'react';
import { LanguageContext } from "../LanguageContext";
import MilitaryTechIcon from '@mui/icons-material/MilitaryTech';
import Chip from '@mui/material/Chip';
import { MultiScriptTextInput } from '../Common/MultiScriptTextInput';
import SearchIcon from '@mui/icons-material/Search';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';

export interface SearchProps {
    pending: boolean,
    searchWord: string,
    onSearch: () => void,
    onChange: (searchWord: string) => void,
    inputRef: MutableRefObject<any>
}

export const Search = React.memo((props: SearchProps) => {
    console.log('Search rendering')
    const { language, country } = useContext(LanguageContext);

    return (        
        <React.Fragment>
            <MultiScriptTextInput inputRef={props.inputRef} onEnter={props.onSearch} onChange={props.onChange} value={props.searchWord} keyboard={country.keyboard} label={"Search definitions"} multiInputsMode={true} allowEmpty={true} />            
            <Stack direction={{ xs: 'column', sm: 'row' }} spacing={{ xs: 1, sm: 2 }} className='add-definition-buttons' >                
                <Button variant="contained" onClick={props.onSearch} disabled={ !language || props.pending} endIcon={<SearchIcon />}>
                    Search
                </Button>             
            </Stack>
        </React.Fragment>);
});