import React, { useState, useContext, MutableRefObject } from 'react';
import { LanguageContext } from "../LanguageContext";
import { MultiScriptTextInput } from "../Common/MultiScriptTextInput";
import Button from '@mui/material/Button';
import AddIcon from '@mui/icons-material/Add';
import SearchIcon from '@mui/icons-material/Search';
import Stack from '@mui/material/Stack';

export interface SearchBoxProps {
    pending: boolean,
    searchWord: string,
    isAddSelectedEnable: boolean,
    onSearch: () => void,
    onAddCustom: () => void,
    onChange: (searchWord: string) => void,
    onAddSelected: () => void,
    inputRef: MutableRefObject<any>
}

export const SearchBox = (props: SearchBoxProps) => {
    console.log('SearchBox rendering')
    const { language, country } = useContext(LanguageContext);

    const search = () => {
        props.onSearch();
    }

    return (
        <React.Fragment>
            <MultiScriptTextInput inputRef={props.inputRef} onEnter={props.onSearch} onChange={props.onChange} value={props.searchWord} keyboard={country.keyboard} label={"Search definitions"} multiInputsMode={true} allowEmpty={false} />            
            <Stack direction={{ xs: 'column', sm: 'row' }} spacing={{ xs: 1, sm: 2 }} className='add-definition-buttons' >                
                <Button variant="contained" onClick={props.onSearch} disabled={!props.searchWord || !language || props.pending} endIcon={<SearchIcon />}>
                    Search
                </Button>
                <Button variant="contained" onClick={props.onAddSelected} disabled={props.pending || !props.isAddSelectedEnable} endIcon={<AddIcon />}>
                    Add selected
                </Button>
                <Button variant="contained" disabled={props.pending} onClick={props.onAddCustom} endIcon={<AddIcon />}>
                    Add my own
                </Button>              
            </Stack>
        </React.Fragment>
    );
};