import React from 'react';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Button from '@mui/material/Button';
import { WhiteboardEdit } from './WhiteboardEdit'

export interface WhiteboardDialogProps {
    open: boolean;
    whiteboard: string;
    wideMode: boolean;
    onSave: (newVal: string) => void;
    onCancel: () => void;
}

export const WhiteboardDialog = React.memo((props: WhiteboardDialogProps) => {
    console.log('WhiteboardDialog rendering')
    return (
        <Dialog open={props.open} fullWidth={true} maxWidth={"lg"} fullScreen={!props.wideMode}>
            <DialogTitle>Whiteboard</DialogTitle>
            <DialogContent dividers={true} style={{ backgroundColor: '#f7f7f7', padding: !props.wideMode ? "0 !important" : "inherit" }}>
                <WhiteboardEdit onChange={props.onSave} onCancel={props.onCancel} value={props.whiteboard}></WhiteboardEdit>
            </DialogContent>

            <DialogActions>
                <Button variant="outlined" onClick={props.onCancel}>Cancel</Button>
            </DialogActions>
        </Dialog>
    );
});