import React, { useEffect, useRef, useState } from 'react';
import { FlashcardFront } from './FlashcardFront';
import { FlashcardBack } from './FlashcardBack';
import Slide from '@mui/material/Slide';
import { Definition } from '../../api-models';

export interface FlashcardProps {
    definition: Definition;
    isReverseTest: boolean;
    mode: "test" | "learn" | "edit";
    onResponse: (id: string, result: boolean, testVersion: number) => void;
    onSave: (definition: Definition, whiteboard: string) => Promise<any>;
    onRemove: (definition: Definition) => void;
}

export const Flashcard = (props: FlashcardProps) => {  
    console.log('Flashcard rendering')    
    const [showDefinitionSide, setShowDefinitionSide] = useState(true);
    const [show, setShow] = useState(true);
    const [direction, setDirection] = useState("right");
    const containerRef = useRef(null);
    const mounted = useRef(false);

    const toggleCard = () => {
        setShowDefinitionSide(!showDefinitionSide);
    }
    const onResponse = (id: string, result: boolean, testVersion: number) => {
        if (result)
            setDirection("left");
        setShow(false);
        setTimeout(() => {
            if (result)
                setDirection("right");
            props.onResponse(id, result, testVersion);
            if (mounted.current)
                setShow(true);
        }, 600);
    }
    const saveChanges = async (def: Definition, whiteboard: string) => {
        await props.onSave(def, whiteboard);
    }
    const getFlipClasses = () => {
        return !showDefinitionSide ? "flipFront rotated" : "flipFront";
    }
    
    useEffect(() => {
        mounted.current = true;
        return () => {
            mounted.current = false;
        }; 
    }, []);

    return (
        <React.Fragment>
            <Slide direction={direction as 'left' | 'right' | 'up' | 'down'} appear={true} in={show} container={containerRef.current} timeout={500}>
                <div className={getFlipClasses()}>
                    <div className="flipFrontInner">
                        <FlashcardFront isTestMode={props.mode === "test"} isReverseTestMode={props.isReverseTest} definition={props.definition} toggleCard={toggleCard}></FlashcardFront>
                        <div className="flip-box back">
                            <FlashcardBack
                                definition={props.definition}
                                mode={props.mode}
                                onClose={() => {}}
                                onResponse={onResponse}
                                toggleCard={toggleCard}
                                onSave={saveChanges}
                                editMode={false}
                                onRemove={props.onRemove}
                            />
                        </div>
                    </div>
                </div>
            </Slide>
        </React.Fragment>
)};