import React from 'react';

export interface ILetter {
    value: string;
    translation: string;
    toSpeak: string;
    result: boolean;
    visible: boolean;
}

export interface ILettersItemProps {
    letter: ILetter;
    active: boolean;
}

export const LettersItem = React.memo((props: ILettersItemProps) => {
    console.log('LettersItem rendering')
    
    return (
        <div className={`letter-item ${props.letter.visible ? "" : "to-test"} ${props.active ? "active" : ""}`}>
            <div className={`value`}>{props.letter.value}</div>
            { props.letter.visible && <div className={`result ${props.letter.result ? "success" : "failure"}`}>{props.letter.translation}</div>}
        </div>
    );
});