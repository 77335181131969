import React from 'react';
import { Button, IconButton, Snackbar } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

export interface UndoDialogProps {
    open: boolean,
    timeout: number,
    message: string,
    onClose: () => void,
    onUndo: () => void,
}

export const UndoDialog = (props: UndoDialogProps) => {
    console.log('UndoDialog rendering...');    
    const action = (
        <React.Fragment>
          <Button color="primary" size="small" onClick={props.onUndo}>
            Undo
          </Button>
          <IconButton
            size="small"
            aria-label="close"
            color="inherit"
            onClick={props.onClose}
          >
            <CloseIcon fontSize="small" />
          </IconButton>
        </React.Fragment>
      );
    
    return (
        <Snackbar
            open={props.open}
            autoHideDuration={props.timeout}
            onClose={props.onClose}
            anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
            message={props.message}
            action={action}
        />
    );
};