import React from 'react';
import Button from '@mui/material/Button';
import reactCSS from 'reactcss'
import ScreenRotationIcon from '@mui/icons-material/ScreenRotation';
import useMediaQuery from '@mui/material/useMediaQuery';
import { Definition } from '../../api-models';
import { InlineList } from '../Common/InlineList';
import { WordGender } from './WordGender';

export interface FlashcardFrontProps {
    definition: Definition;
    isTestMode: boolean;
    isReverseTestMode: boolean;
    toggleCard: () => void;
}

export const FlashcardFront = (props: FlashcardFrontProps) => {    
    console.log('FlashcardFront rendering')
    const matches = useMediaQuery('(min-width:768px)');
    const descriptionStyle = reactCSS({
        'default': {
            description: !matches ? {
                fontSize: '1.6rem',
                display: 'block',                
                maxHeight: '200px',
                lineHeight: '1'
            } : { }
        }
    });

    return (
        <React.Fragment>
            <div className="flip-box flashcard-box front">
                <div className="flashcard-toolbar">
                    <Button className="toolbar-btn" variant="contained" onClick={props.toggleCard} endIcon={<ScreenRotationIcon />}>
                        {props.isTestMode ? "Try to guess and reverse to check the answer" : "Reverse and try to remember"}
                    </Button>
                </div>
                <div className="flashcard-body">
                    <div className="flashcard-area meaning-box">
                        { !props.isReverseTestMode && <div className="flex-column meaning-box">
                            <div className="flex-row">
                                {props.definition.definition && <div className="definition">
                                    <span style={descriptionStyle.description}>{props.definition.definition}</span></div>}
                                {props.definition.translations && props.definition.translations.length > 0 &&
                                <div className="definition"> 
                                    <InlineList items={props.definition.translations} title={""} style={descriptionStyle.description} />
                                </div>}
                            </div>
                            <div className="custom-definition">{props.definition.customDefinition}</div>
                            <div>{props.definition.partOfSpeech}</div>
                        </div> }
                        { props.isReverseTestMode && <div className="flex-column meaning-box">
                            <div className="flex-row">
                                <div className="definition">
                                <span style={descriptionStyle.description}><WordGender partOfSpeech={props.definition.partOfSpeech} grammaticalFeatures={props.definition.grammaticalFeatures}/> {props.definition.word}</span>
                                </div>
                            </div>
                            <div>{props.definition.partOfSpeech}</div>
                        </div>}
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
};