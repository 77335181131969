import React, { useState, useContext, useEffect, useRef } from 'react';
import { LanguageContext } from "../LanguageContext";
import { DefinitionsList } from '../Definitions/DefinitionsList';
import { FlashcardEdit } from '../Flashcard/FlashcardEdit';
import { useHistory } from 'react-router-dom';
import { distionarySearchCall, wordsAddCall, wordsAddCustomCall } from '../../api-definitions';
import { Pending } from '../Common/Pending';
import { Container } from 'reactstrap';
import { MeaningsList } from './MeaningsList';
import { Definition, Meaning, createEmptyDefinition } from '../../api-models';
import { SearchBox } from './SearchBox';

export const AddDefinitions = () => {
    console.log('AddDefinitions rendering')
    const [searchWord, setSearchWord] = useState('');
    const [meanings, setMeanings] = useState([] as Meaning[]);
    const { language } = useContext(LanguageContext);
    const [definitions, setDefinitions] = useState([] as Definition[]);
    const [newDefinition, setNewDefinition] = useState({} as Definition);
    const history = useHistory();
    const [pending, setPending] = useState(false);
    const inputRef = useRef<any>(null);

    const handleMeaningSelect = (id: string) => {
        setMeanings(m => m.map(x => x.id === id ? {...x, selected: !x.selected } : x ));
    }

    const search = () => {
        setSearchWord(searchWord);
        setDefinitions([]);
        setMeanings([]);
        setNewDefinition({} as Definition)
        setPending(true)
        distionarySearchCall(searchWord, language)
            .then(response => {
                setPending(false);
                setMeanings(response.data);
            })
            .catch(() => {
                setPending(false);
            });
    }

    const onSearchChange = (search: string) => {
        setSearchWord(search);
    }

    const addSelected = () => {
        setPending(true)
        const toSend = {
            definitions: meanings.filter(x => x.selected && !x.disabled)
        };
        wordsAddCall(language, toSend)
            .then(response => {
                setPending(false)
                setSearchWord('');
                setMeanings([]);
                setDefinitions(response.data);
                setNewDefinition({} as Definition);
                if (inputRef && inputRef.current && inputRef.current.focus)
                    inputRef.current.focus();
            })
            .catch(() => {
                setPending(false);
            });
    }

    const cancel = () => {
        setNewDefinition({} as Definition)
    }
    
    const addCustom = () => {
        setMeanings([]);
        setDefinitions([]);
        setSearchWord(searchWord);
        setNewDefinition(createEmptyDefinition(searchWord.toLocaleLowerCase()));
    }

    const saveCustom = (definition: Definition, whiteboard: string) => {
        setPending(true)
        return wordsAddCustomCall(language, definition, whiteboard)
            .then(response => {
                setPending(false)
                setSearchWord('');
                setMeanings([]);
                setDefinitions([response]);
                setNewDefinition({} as Definition);
                if (inputRef && inputRef.current && inputRef.current.focus)
                    inputRef.current.focus();
            })
            .catch(() => {
                setPending(false);
            });
    }

    const isAnySelected = (): boolean => meanings.length > 0 && meanings.findIndex(x => x.selected && !x.disabled) >= 0;

    const onDefinitionChange = (definition: any) => {
        setDefinitions(d => d.map(x => x.id === definition.id ? {...definition } : x ));
    }

    const onDefinitionRemoved = (definition: any) => {
        setDefinitions(d => d.filter(x => x.id !== definition.id));
    }

    useEffect(() => {
        if (!language) {
            history.push("/plan");
        }
        if (inputRef && inputRef.current && inputRef.current.focus)
            inputRef.current.focus();
    }, [language]);

    return (
        <Container>
            <h3>Add flashcard</h3>
            <SearchBox inputRef={inputRef} searchWord={searchWord} isAddSelectedEnable={isAnySelected()} onAddCustom={addCustom} onAddSelected={addSelected} pending={pending} onSearch={search} onChange={onSearchChange}/>
            { pending && <Pending />}
            { !pending && <React.Fragment>
                { meanings.length > 0 && <MeaningsList meanings={meanings} onSelect={handleMeaningSelect} /> }            
                { definitions.length > 0 && <React.Fragment>
                    <h4>You can modify your flashcards</h4>
                    <DefinitionsList onRemove={onDefinitionRemoved} definitions={definitions} isMore={false} onSave={onDefinitionChange} />
                </React.Fragment>}
                { newDefinition.id && <React.Fragment>
                    <h4>Fill and create</h4>
                    <FlashcardEdit definition={newDefinition} editMode={true} onSave={saveCustom} onCancel={cancel} onClose={cancel} onRemove={cancel} />
                </React.Fragment>}
            </React.Fragment> }
        </Container>
    );
};