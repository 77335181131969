import React from 'react';
import { Meaning } from '../../api-models';
import { MeaningsListItem } from './MeaningsListItem';

export interface MeaningsListProps {
    meanings: Meaning[],
    onSelect: (id: string) => void,
}

export const MeaningsList = (props: MeaningsListProps) => {
    console.log('MeaningsList rendering')
    const onClick = (meaning: Meaning) => {
        if (!meaning.disabled) props.onSelect(meaning.id);
    }
    return (
        <React.Fragment>{ props.meanings.length > 0 && 
            <table className='table table-striped' aria-labelledby="tabelLabel">
                <tbody>
                { props.meanings.map(meaning =>
                    <tr style={{ opacity: meaning.disabled ? '0.5' : '1'}} key={meaning.id} onClick={() => onClick(meaning)}>
                        <MeaningsListItem meaning={meaning} />
                    </tr>
                    )}
                </tbody>
            </table>}
        </React.Fragment>
    );
};