import React from 'react';
import Button from '@mui/material/Button';
import ThumbUpIcon from '@mui/icons-material/ThumbUp';
import ThumbDownIcon from '@mui/icons-material/ThumbDown';
import useMediaQuery from '@mui/material/useMediaQuery';

export interface ToolbarTestModeProps {
    onResponse: (result: boolean) => void;
}

export const ToolbarTestMode = (props: ToolbarTestModeProps) => {
    console.log('ToolbarTestMode rendering')
    const matches = useMediaQuery('(min-width:768px)');

    return (
        <React.Fragment>
            {!matches && <React.Fragment>
                <Button className="toolbar-btn" color="error" variant="outlined" onClick={() => { props.onResponse(false) }}>
                    <ThumbDownIcon />
                </Button>
                <Button className="toolbar-btn" variant="contained" color="success" onClick={() => { props.onResponse(true) }}>
                    <ThumbUpIcon />
                </Button>
                </React.Fragment>}
                {matches && <React.Fragment>
                    <Button className="toolbar-btn" color="error" variant="outlined" onClick={() => { props.onResponse(false) }} endIcon={<ThumbDownIcon />}>
                        Incorrect
                            </Button>
                    <Button className="toolbar-btn" variant="contained" color="success" onClick={() => { props.onResponse(true) }} endIcon={<ThumbUpIcon />}>
                        Correct!
                </Button>
            </React.Fragment>}
        </React.Fragment>
    );
};