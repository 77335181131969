import React from 'react';
import MilitaryTechIcon from '@mui/icons-material/MilitaryTech';
import Chip from '@mui/material/Chip';

export interface ProgressProps {
    retiredCount: number,
    count: number,
}

export const Progress = React.memo((props: ProgressProps) => {
    console.log('Progress rendering')

    const counters = <span>
        <MilitaryTechIcon fontSize={"medium"} color={"success"}></MilitaryTechIcon> 
        { "You've learned: " + props.retiredCount + " of " + props.count }
    </span>;
    return (        
        <Chip label={counters} color="success" variant="outlined" />       
    );
});