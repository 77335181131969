import React from 'react';
import { Badge, Chip } from '@mui/material';

export interface SessionProgressProps {
    testMode: boolean,
    failedCount: number,
    succeedCount: number,
    totalCount: number,
}

export const SessionProgress = (props: SessionProgressProps) => {
    console.log('SessionProgress render') 
    const doneCount = props.failedCount + props.succeedCount; 
    const toDoCount = props.totalCount - doneCount;
    const title = props.testMode ? 'Test' : 'Learn';
    return (
        <div style={{ margin: '10px', justifyContent: 'space-between', display: 'flex' }}>
            <div>{props.testMode && <Badge badgeContent={props.failedCount} color="error">
                <Chip label="Wrong answers" color="error" variant="outlined" />
            </Badge>}</div>
            {toDoCount > 0 &&
                <Chip label={title +": " + doneCount + " of " + props.totalCount} variant="outlined" />}
            {toDoCount === 0 &&  
                <Chip label={title +" all " + props.totalCount + " of " + props.totalCount} variant="outlined" />}
            <div>{props.testMode && <Badge badgeContent={props.succeedCount} color="success">
                <Chip label="Correct answers" color="success" variant="outlined" />
            </Badge>}</div>
        </div>
    );
};