import React from 'react';
import AssignmentTurnedInOutlinedIcon from '@mui/icons-material/AssignmentTurnedInOutlined';
import moment from 'moment';

export interface SessionSummaryProps {
    lastTestedDate: Date,
}

export const SessionSummary = (props: SessionSummaryProps) => {
    console.log('SessionSummary render') 
    const lastSessionDate = moment(props.lastTestedDate);
    const sessionStartedDaysAgo = moment.utc().diff(lastSessionDate, "days");
    return (
        <div style={{ fontSize: '1.9rem', textAlign: 'center', color: 'silver' }}>
            {props.lastTestedDate && <React.Fragment>
                <div>Session completed!</div>
                {sessionStartedDaysAgo < 1 && <div style={{ fontSize: '1.5rem' }} >Start the new one tomorrow.</div> }
                {sessionStartedDaysAgo >= 1 && <div style={{ fontSize: '1.5rem' }} >It is time to start the new one!</div> }
                <AssignmentTurnedInOutlinedIcon style={{ fontSize: '20rem', color: 'silver' }} />
            </React.Fragment>}
            {!props.lastTestedDate && <React.Fragment>
                <div>Let's begin! Start the new session!</div>
            </React.Fragment>}
        </div>
    );
};