import React, { useState, useEffect, useContext, useRef } from 'react';
import { LanguageContext } from "../LanguageContext";
import { DefinitionsList } from './DefinitionsList';
import { useHistory } from 'react-router-dom';
import { wordsCountCall, wordsGetCall } from '../../api-definitions';
import { Pending } from '../Common/Pending';
import { Progress } from './Progress';
import { Definition } from '../../api-models';
import { Search } from './Search';
import SearchIcon from '@mui/icons-material/Search';
import { IconButton } from '@mui/material';

export const Definitions = ({}) => {
    console.log('Definitions rendering')
    const [page, setPage] = useState(0);
    const [showSearch, setShowSearch] = useState(false);
    const [searchWord, setSearchWord] = useState('');
    const [count, setCount] = useState({} as any);
    const [isMore, setIsMore] = useState(false);
    const [loaded, setLoaded] = useState(false);
    const [searchPending, setSearchPending] = useState(false);
    const [definitions, setDefinitions] = useState([] as Definition[]);
    const { language } = useContext(LanguageContext);
    const history = useHistory();
    const inputRef = useRef<any>(null);

    const get = () => {
        wordsGetCall(page, language, searchWord)
            .then(response => {
                if (page === 0) {
                    setDefinitions(response.data);
                } else {
                    setDefinitions(p => p.concat(response.data.slice(0, 99)));
                }
                //TODO: check if last won't be missing
                setIsMore(response.data.length > 99);
                setLoaded(true);
                setSearchPending(false)
            });
    }

    const search = () => {
        setSearchPending(true)
        get();        
    }

    const updateLocally = (definition: Definition) => {
        const index = definitions.findIndex(x => x.id === definition.id);
        if (index > -1) {
            const newDefinitions = [...definitions];
            const newDefinition = {
                ...definition
            };
            newDefinitions.splice(index, 1, newDefinition);
            setDefinitions(newDefinitions);
        }
    }

    const getCount = () => {
        wordsCountCall(language)
            .then(response => {
                setCount(response.data);
            });
    }

    const loadMore = () => {
        setPage(p => p + 1);
    }

    const onSearchChange = (search: string) => {
        setSearchWord(search);
    }

    useEffect(() => {
        get();
    }, [page]);


    useEffect(() => {
        getCount();
    }, []);

    useEffect(() => {
        if (!language) {
            history.push("/plan");
        }
    }, [language]);

    return (        
        <div className='container definitions'>
            { loaded && <React.Fragment>
                    <div><h3>Flashcards {count && count.count && <Progress count={count.count} retiredCount={count.retiredCount} />}                    
                    <IconButton color="primary" aria-label="Search"  onClick={() => { setShowSearch(p => !p) }}>
                        <SearchIcon />
                    </IconButton>
                    </h3></div>
                    { showSearch && 
                        <Search inputRef={inputRef} searchWord={searchWord} pending={searchPending} onSearch={search} onChange={onSearchChange}/>
                    }
                    {definitions.length > 0 && <div>
                        <DefinitionsList onRemove={get} definitions={definitions} isMore={isMore} getMore={loadMore} onSave={updateLocally} />
                    </div>}
                </React.Fragment>
            }            
            { !loaded && <div>
                <h3>Flashcards</h3>
                <Pending />
            </div>}      
        </div>        
    );
};