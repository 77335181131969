export interface PendingAction {
    actionId: string,
    id: string,
    type: 'test' | 'learn',
    result: boolean,
    testVersion: number,
    timeStamp: Date,
}

const pendingActionStorageKey = "pending-action";

const add = (id: string, result: boolean, testVersion: number, type: 'test' | 'learn') : PendingAction  => {   
    console.log('addPendingAction ' + id) 
    const pendingAction = {
        actionId:  id + testVersion + new Date().getTime(),
        id: id,
        result: result,
        testVersion: testVersion,
        type: type
    } as PendingAction;

    sessionStorage.setItem(pendingActionStorageKey, JSON.stringify(pendingAction));
    return pendingAction
}

const clean = () => {    
    sessionStorage.setItem(pendingActionStorageKey, '')
}

const get = (): PendingAction | null => {
    const pendingActionStr = sessionStorage.getItem(pendingActionStorageKey);
    const pendingAction: PendingAction | null = pendingActionStr?.length ? JSON.parse(pendingActionStr) : null;
    return pendingAction
};

export default { add, clean, get }