import axios, { AxiosRequestConfig } from "axios";
import { getToken, getBaseUrl } from './index';

const createHeaders = (language: string, token: string): AxiosRequestConfig => {
    return {
        headers: {
            'Content-Type': 'application/json',
            'X-LANGUAGE': language,
            'Authorization': 'Bearer ' + token
        }
      };
}
const getRaw = async (url: string) => {
    return axios.get(url);
};
const get = async (url: string, language: string) => {
    const token = await getToken();
    return axios.get(getBaseUrl() + url, createHeaders(language, token));
};
const post = async (url: string, language: string, data: any) => {
    const token = await getToken();
    return axios.post(getBaseUrl() + url, data, createHeaders(language, token));
};
const put = async (url: string, language: string, data: any) => {
    const token = await getToken();
    return axios.put(getBaseUrl() + url, data, createHeaders(language, token));
};
const remove = async (url: string, language: string) => {
    const token = await getToken();
    return axios.delete(getBaseUrl() + url, createHeaders(language, token));
};

const HttpCommon = {
    get,
    post,
    put,
    remove,
    getRaw,
};
export default HttpCommon;