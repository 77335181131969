import React, { useState } from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Slider from "@mui/material/Slider";
import Typography from "@mui/material/Typography";

export interface TestSetDialogProps {
    onSave: (dailyTestCapacity: number, reverseTestFrequency: number) => void,
    onCancel: () => void,
    open: boolean,
    dailyTestCapacity: number,
    reverseTestFrequency: number,
}

export const TestSetDialog = React.memo((props: TestSetDialogProps) => {
    console.log('TestSetDialog is rendering...')
    const [dailyTestCapacity, setDailyTestCapacity] = useState(props.dailyTestCapacity);
    const [reverseTestFrequency, setReverseTestFrequency] = useState(props.reverseTestFrequency);

    const onSave = () => {
        props.onSave(dailyTestCapacity, reverseTestFrequency);
    }

    const onCancel = () => {
        setDailyTestCapacity(props.dailyTestCapacity)
        setReverseTestFrequency(props.reverseTestFrequency)
        props.onCancel();
    }

    const marks = [
        {
            value: 5,
            label: 5,
        },
        {
            value: 20,
            label: 20,
        },
        {
            value: 35,
            label: 35,
        },
        {
            value: 50,
            label: 50,
        },
        {
            value: 75,
            label: 75,
        },
    ];

    const marksReverseTestFrequency = [
        {
            value: 0,
            label: "Never",
        },
        {
            value: 3,
            label: "3/10",
        },
        {
            value: 5,
            label: "5/10",
        },
        {
            value: 7,
            label: "7/10",
        },
        {
            value: 10,
            label: "Always",
        }
    ];

    const onChange = (e: Event, v: number | number[], at: number) => {
        setDailyTestCapacity(v as number)
    }

    const onChangeReverseTestFrequency = (e: Event, v: number | number[], at: number) => {
        setReverseTestFrequency(v as number)
    }

    return (
        <Dialog open={props.open} fullWidth={false} maxWidth={"lg"} fullScreen={false}>
            <DialogTitle>Update settings</DialogTitle>
            <DialogContent dividers={true} >
                <Typography id="slider">How many flashcards would you like to learn daily?</Typography>
                <Slider
                    step={1}
                    min={5}
                    max={75}
                    aria-labelledby="slider"
                    valueLabelDisplay="auto"
                    marks={marks}
                    value={dailyTestCapacity}
                    onChange={onChange} />
                <Typography id="slider">How often would you like to be tested from word to definition?</Typography>
                <Slider
                    step={1}
                    min={0}
                    max={10}
                    aria-labelledby="slider"
                    valueLabelDisplay="auto"
                    marks={marksReverseTestFrequency}
                    value={reverseTestFrequency}
                    onChange={onChangeReverseTestFrequency} />
            </DialogContent>
            <DialogActions>
                <Button variant="contained" onClick={onSave}>Save</Button>
                <Button variant="outlined" onClick={onCancel}>Cancel</Button>
            </DialogActions>
        </Dialog>
    );
});