import React, { useState, useEffect } from 'react';
import { SketchField, Tools } from 'react-sketch';
import { ColorPicker } from '../Common/ColorPicker';
import Slider from "@mui/material/Slider";
import Box from "@mui/material/Box";
import UndoIcon from "@mui/icons-material/Undo";
import RedoIcon from "@mui/icons-material/Redo";
import DeleteIcon from "@mui/icons-material/Delete";
import SaveIcon from "@mui/icons-material/Save";
import AddIcon from "@mui/icons-material/Add";
import CircularProgress from '@mui/material/CircularProgress';
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import TextField from "@mui/material/TextField";
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import SearchIcon from '@mui/icons-material/Search';
import Button from '@mui/material/Button';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import CircleOutlinedIcon from '@mui/icons-material/CircleOutlined';
import CropLandscapeOutlinedIcon from '@mui/icons-material/CropLandscapeOutlined';
import BrushOutlinedIcon from '@mui/icons-material/BrushOutlined';
import DriveFileRenameOutlineIcon from '@mui/icons-material/DriveFileRenameOutline';
import TouchAppIcon from '@mui/icons-material/TouchApp';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import useMediaQuery from '@mui/material/useMediaQuery';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle'
import { searchIconCall } from '../../api-definitions';
import './WhiteboardEdit.css';

export interface WhiteboardProps {
    value: any,
    onChange: (value: string) => void,
    onCancel: () => void
}

export const WhiteboardEdit = (props: WhiteboardProps) => {
    console.log('WhiteboardEdit rendering')
    const [lineWidth, setLineWidth] = useState(3);
    const [fontSize, setFontSize] = useState(20);
    const [canUndo, setCanUndo] = useState(false);
    const [dialogOpen, setDialogOpen] = useState(false);
    const [canRedo, setCanRedo] = useState(false);
    const [isSelected, setIsSelected] = useState(false);
    const [searching, setSearching] = useState(false);
    const [lineColor, setLineColor] = useState("black");
    const [fillColor, setFillColor] = useState("black");
    const [backgroundColor, setBackgroundColor] = useState("white");
    const [text, setText] = useState("");
    const [searchIconsText, setSearchIconsText] = useState("");
    const [icons, setIcons] = useState([]);
    const [tool, setTool] = useState(Tools.Select);
    const matches = useMediaQuery('(min-width:768px)');
    let sketch;

    const undo = () => {
        sketch.undo();
        onChange();
    };

    const onChange = () => {
        if (!!(sketch?.canUndo && sketch?.canRedo)) {
            setCanUndo(sketch?.canUndo() ?? false);
            setCanRedo(sketch?.canRedo() ?? false);
            const canvas = sketch?._fc;
            setIsSelected(!!canvas?.getActiveObject());
        }
    };

    const onSave = () => {
        props.onChange(JSON.stringify(sketch.toJSON()));
    };

    const redo = () => {
        sketch.redo();
        onChange();
    };

    const clear = () => {
        sketch.clear();
        onChange();
        setDialogOpen(false);
    };

    const removeSelected = () => {
        sketch.removeSelected();
        onChange();
    }

    const searchIcons = () => {
        setSearching(true);
        searchIconCall(searchIconsText)
            .then(response => 
            {
                setSearching(false);
                return response.data;
            })
            .then(data => {
                setIcons(data);
            });
    }

    const updateSelected = () => {
        let canvas = sketch._fc;
        let activeObj = canvas.getActiveObject();
        if (activeObj) {
            let selected = new Array<any>();
            if (activeObj.type === "activeSelection") {
                activeObj.forEachObject((obj) => selected.push(obj));
            } else {
                selected.push(activeObj);
            }
            selected.forEach((obj) => {
                if (obj.__originalState.type === "circle"
                    || obj.__originalState.type === "rect") {
                    obj.setColor(fillColor);
                }
                obj.set("stroke", lineColor);
                let objState = obj.toJSON();
                obj.__originalState = objState;
                let state = JSON.stringify(objState);
                sketch._history.keep([obj, state, state]);
            });
            canvas.requestRenderAll();
        }
    }

    const addText = () => {
        sketch.addText(text,
            {
                fill: lineColor,
                fontFamily: 'Helvetica',
                fontSize: fontSize
            });
        setText("");
    };

    const addImage = (url) => {
        sketch.addImg(url);
        setSearchIconsText('');
        setIcons([]);
    };

    const handleClickClearAll = () => {
        setDialogOpen(true);
      };

    const keyDownHandler = (event: React.KeyboardEvent<HTMLInputElement>) => {
        if ((event.code === "Enter" || event.keyCode === 13 || event.key === "Enter") && searchIconsText) {
            searchIcons();
        }
    };
    const handleDialogClose = () => {
        setDialogOpen(false);
    };

    const handleSearcText = (e: any) => {
        const newValue = e.target.value;
        setIcons([]);
        setSearchIconsText(newValue);
    }
    const handleChangeTool = (event, tool) => {
        setTool(tool);
    };
    useEffect(() => {
        updateSelected();
    }, [lineWidth, fontSize, lineColor, fillColor]);

    return (
        <React.Fragment>
            <div>
                <div className="top-bar">
                    <ToggleButtonGroup
                        color="primary"
                        value={tool}
                        size={matches ? "medium" : "small"}
                        exclusive
                        onChange={handleChangeTool}
                    >
                        <ToggleButton value={Tools.Select}><TouchAppIcon /></ToggleButton>
                        <ToggleButton value={Tools.Pencil}><BrushOutlinedIcon /></ToggleButton>
                        <ToggleButton value={Tools.Circle}><CircleOutlinedIcon /></ToggleButton>
                        <ToggleButton value={Tools.Rectangle}><CropLandscapeOutlinedIcon /></ToggleButton>
                        <ToggleButton value={Tools.Line}><DriveFileRenameOutlineIcon /></ToggleButton>
                    </ToggleButtonGroup>
                    <IconButton
                        color="primary"
                        size={matches ? "medium" : "small"}
                        disabled={!canUndo}
                        onClick={undo}>
                        <UndoIcon/>
                    </IconButton>
                    <IconButton
                        color="primary"
                        disabled={!canRedo}
                        size={matches ? "medium" : "small"}
                        onClick={redo}>
                        <RedoIcon/>
                    </IconButton>
                    <IconButton color="primary" onClick={onSave} size={matches ? "medium" : "small"}>
                        <SaveIcon/>
                    </IconButton>
                    <IconButton color="primary" disabled={!isSelected} onClick={removeSelected} size={matches ? "medium" : "small"}>
                        <HighlightOffIcon/>
                    </IconButton>
                    <IconButton color="primary" onClick={handleClickClearAll} size={matches ? "medium" : "small"}>
                        <DeleteIcon />
                    </IconButton>
                </div>
            </div>
            <Dialog
                open={dialogOpen}
                onClose={handleDialogClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">
                    {"Are you soure?"}
                </DialogTitle>
                <DialogContent>
                    {"Are you soure you want do clear the whiteboard?"}
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleDialogClose}>Cancel</Button>
                    <Button onClick={clear} autoFocus>Clear all</Button>
                </DialogActions>
            </Dialog>
            <div className="whiteboard-edit">
                <div className="flex-row sketch-box" style={{ flexGrow: 2, justifyContent: "center"}}>
                    <SketchField
                        width='300px'
                        height='200px'
                        tool={tool}
                        ref={(c) => (sketch = c)}
                        undoSteps={15}
                        value={props.value}
                        lineColor={lineColor}
                        fillColor={fillColor}
                        onChange={onChange}
                        backgroundColor={backgroundColor}
                        lineWidth={lineWidth} />
                </div>
                <div className="toolbar">
                    <div className="toolbar-inner">
                        <Accordion>
                            <AccordionSummary
                                expandIcon={<ExpandMoreIcon />}
                                aria-controls="panel3a-content"
                                id="panel3a-header">
                                <Typography>Colors</Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                                <Typography>Line</Typography>
                                <Box className="color-picker-wb">
                                        <ColorPicker type="sketch" color={lineColor} onChange={setLineColor} fullWidth={true}></ColorPicker></Box>
                                <Typography>Fill</Typography>
                                <Box className="color-picker-wb">
                                        <ColorPicker type="sketch" color={fillColor} onChange={setFillColor} fullWidth={true}></ColorPicker>
                                </Box>
                                <Typography>Background</Typography>
                                <Box className="color-picker-wb">
                                        <ColorPicker type="sketch" color={backgroundColor} onChange={setBackgroundColor} fullWidth={true}></ColorPicker>
                                </Box>
                            </AccordionDetails>
                        </Accordion>
                        <Accordion>
                            <AccordionSummary
                                expandIcon={<ExpandMoreIcon />}
                                aria-controls="panel3a-content"
                                id="panel3a-header">
                                <Typography>Icons</Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                                <TextField
                                    fullWidth
                                    id="search"
                                    label="Search icons"
                                    placeholder="search icons..."
                                    autoComplete="off"
                                    onKeyDown={keyDownHandler}
                                    onChange={handleSearcText}
                                    value={searchIconsText}/>
                                <Button className="form-button" variant="contained" onClick={searchIcons} disabled={!searchIconsText || searching} endIcon={<SearchIcon />}>
                                    Search
                                </Button>                            
                                { searching && <div><CircularProgress disableShrink /></div>}
                                {icons && !searching && <div className="flex-row search-result">
                                    {icons.map((icon: any, i) =>
                                        <div className="image" key={i} onClick={() => { addImage(icon.url); }}>
                                            <img style={{width: 'auto', height: 'auto'}} src={icon.url} alt="icon"></img>
                                        </div>
                                    )}
                                </div>}
                            </AccordionDetails>
                        </Accordion>
                        <Accordion>
                            <AccordionSummary
                                expandIcon={<ExpandMoreIcon />}
                                aria-controls="panel3a-content"
                                id="panel3a-header">
                                <Typography>Text</Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                                <TextField
                                    fullWidth
                                    label="Add text"
                                    placeholder="add text..."
                                    onChange={(e) => setText(e.target.value)}
                                    value={text} />
                                <Button className="form-button" variant="contained" onClick={addText} disabled={!text} endIcon={<AddIcon />}>
                                    Add text
                                </Button>
                            </AccordionDetails>
                        </Accordion>
                        <Accordion>
                            <AccordionSummary
                                expandIcon={<ExpandMoreIcon />}
                                aria-controls="panel2a-content"
                                id="panel2a-header">
                                <Typography>Lines</Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                                <Typography id="slider">Line Weight</Typography>
                                <Slider
                                    step={1}
                                    min={1}
                                    max={20}
                                    aria-labelledby="slider"
                                    value={lineWidth}
                                    onChange={(e: Event, v: number | number[], at: number) => setLineWidth(v as number)} />
                            </AccordionDetails>
                        </Accordion>
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
};