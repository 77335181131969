import React from 'react';
import { FlashcardBack } from './FlashcardBack'

export interface FlashcardProps {
    definition: any;
    onClose: () => void;
    onCancel?: () => void;
    editMode: boolean;
    onSave: (definition: any, whiteboard: string) => Promise<any>;
    onRemove: (definition: any) => void;
}

export const FlashcardEdit = (props: FlashcardProps) => {
    console.log('FlashcardEdit rendering')
    const onClose = () => {
        props.onClose();
    }
    const handleRemove = () => {
        props.onRemove(props.definition);
    }

    const saveChanges = async (def: any, whiteboard: string) => {
        await props.onSave(def, whiteboard);
    }

    return (
        <FlashcardBack
            definition={props.definition}
            mode={"edit"}
            onClose={onClose}
            onResponse={() => {}}
            onSave={saveChanges}
            toggleCard={() => { }}
            editMode={props.editMode}
            onRemove={handleRemove}
            onCancel={props.onCancel}
        />
    );
};