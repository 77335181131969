import React, { useState, useContext, useEffect, useRef } from 'react';
import { LanguageContext } from "../LanguageContext";
import { Flashcard } from '../Flashcard/Flashcard';
import { learningLearnedCall, learningTestCall, wordsDeleteCall, wordsUpdateCall } from '../../api-definitions';
import { SessionProgress } from './SessionProgress';
import { UndoDialog } from './UndoDialog';
import PendingActionService, { PendingAction } from './PendingActionService';
import { Definition } from '../../api-models';

interface CardDefinition extends Definition {
    visible: boolean,
    key: string,
}

export interface CardsSetProps {
    cards: Definition[],
    lastTestedDate: Date,
    reverseTestFrequency: number,
    sessionVersion: number,
    mode: 'test' | 'learn',
    onComplete: () => void,
}

export const isReverseTestCalculation = (mode: 'test' | 'learn', reverseTestFrequency: number) : boolean => {
    const rand = new Date().getTime() % 10
    const result = mode === 'test' && rand < reverseTestFrequency;
    console.log('Res: ' + result)
    return result
};

export const CardsSet = (props: CardsSetProps) => {
    console.log('card set rendering...');
    const totalCount = props.cards.length;
    const allCards: CardDefinition[] = props.cards.map(x => {
        return {...x, visible: true, key: x.id, /*, whiteboardRef: ''*/};
    });
    
    const undoTimeout = 3000;
    const [undoVisible, setUndoVisible] = useState(false);
    const [succeedCount, setSucceedCount] = useState(0);
    const [failedCount, setFailedCount] = useState(0);
    const [cards, setCards] = useState(allCards as CardDefinition[]);
    const [currentCard, setCurrentCard] = useState<CardDefinition | null>(cards.length > 0 ? cards[0] : {} as CardDefinition);
    const { language } = useContext(LanguageContext);
    const [isReverseTestMode, setIsReverseTestMode] = useState(props.reverseTestFrequency === 10);
    const mounted = useRef(false);

    const goNext = (hideCurrent: boolean): void => { 
        const currentIndex = getCurrentIndex();
        const newCard = {
            ...cards[currentIndex],
            key: cards[currentIndex].key + '_',
            visible: !hideCurrent
        };
        const newCards = cards.map(x => x.id === newCard.id ? newCard : x);

        if (newCards.filter(x => x.visible).length === 0) {
            completeSet();
        }
        else {
            selectNextCard(newCards, currentIndex);
        }   
        
        setCards(newCards);     
    }   

    const completeSet = (): void => { 
        setCurrentCard(null);
        setUndoVisible(false);
        forceExecuteAction();
        props.onComplete();   
    }   

    const selectNextCard = (newCards: CardDefinition[], currentIndex: number): void => { 
        let index = currentIndex + 1;
        let newIndex = -1;
        let iterations = 0;
        while (newIndex === -1 && iterations < newCards.length) {
            if (index >= newCards.length) index = 0;
            if (newCards[index].visible) newIndex = index;
            iterations++;
            index++;
        }
        setIsReverseTestMode(isReverseTestCalculation(props.mode, props.reverseTestFrequency))
        setCurrentCard(newCards[newIndex]);     
    } 

    const testResponse = (id: string, result: boolean, testVersion: number) => {           
        console.log('testResponse ' + id)      
        resolveCardResult(id, result, testVersion, 'test');
        goNext(true);
    }

    const learnResponse = (id: string, result: boolean, testVersion: number) => {           
        console.log('learnResponse ' + id)      
        if (result) {
            resolveCardResult(id, result, testVersion, 'learn');
        }        
        goNext(result);
    }
    
    const resolveCardResult = (id: string, result: boolean, testVersion: number, type: 'test' | 'learn') => {      
        console.log('resolveCardResult ' + id)    
        if (result) {
            setSucceedCount(prev => prev + 1);
        } else {
            setFailedCount(prev => prev + 1);
        }   
           
        addPendingAction(id, result, testVersion, type);
    }

    const addPendingAction = (id: string, result: boolean, testVersion: number, type: 'test' | 'learn') => {   
        console.log('addPendingAction ' + id) 
        setUndoVisible(true);
        forceExecuteAction();

        const action = PendingActionService.add(id, result, testVersion, type);
        setTimeout(() => {
            tryExecuteAction(action.actionId);
        }, undoTimeout);
    }

    const executeAction = (action: PendingAction) => { 
        console.log('executeAction ' + action.actionId) 
        if (!action?.id) return;
        PendingActionService.clean();
        if (mounted.current)
            setUndoVisible(false);

        if (action.type === 'learn') {
            learn(action.id, action.testVersion);
        }
        else {
            test(action.id, action.result, action.testVersion)
        }        
    }
    
    const tryExecuteAction = (actionId: string) => {     
        console.log('tryExecuteAction ' + actionId)    
        const pendingAction = PendingActionService.get();
        if (pendingAction && pendingAction.actionId === actionId) {
            executeAction(pendingAction);
        }
    }

    const forceExecuteAction = () => {     
        console.log('forceExecuteAction')    
        const pendingAction = PendingActionService.get();
        if (pendingAction) { 
            executeAction(pendingAction);
        }
    }

    const onResponse = (id: string, result: boolean, testVersion: number) => {
        console.log('onResponse ' + id)     
        if (props.mode === 'test') {
            testResponse(id, result, testVersion);
        }
        else {
            learnResponse(id, result, testVersion);
        }
    }

    const getIndex = (id: string): number => cards.findIndex(x => x.id === id);
    const getCurrentIndex = (): number => cards.findIndex(x => x.id === currentCard?.id);
    const getCard = (id: string): CardDefinition | null => {
        const index = getIndex(id);
        return index === -1 ? null : cards[index];        
    }

    const updateCard = async (definition: Definition, whiteboard: string) => {
        const response = await wordsUpdateCall(definition.id, language, definition, whiteboard);
        const card = getCard(response.id);
        if (!card) return;
        const newCard = {
            ...response,
            visible: card.visible,
            key: card.key,
        } as CardDefinition;
        setCards(c => c.map(x => x.id === newCard.id ? newCard : x));
    }

    const test = (id: string, result: boolean, testVersion: number) => {
        const toSend = {
            result: result,
            testVersion: testVersion,
            sessionVersion: props.sessionVersion
        };      
        learningTestCall(id, language, toSend);
    }

    const remove = (definition: Definition) => {
        goNext(true);
        wordsDeleteCall(definition.id, language);
    }

    const learn = (id: string, testVersion: number) => {
        const toSend = {
            testVersion: testVersion,
            sessionVersion: props.sessionVersion
        }; 
        learningLearnedCall(id, language, toSend);
    }

    const handleUndoClose = () => {
        setUndoVisible(false);
    };

    const handleUndo = () => { 
        const pendingAction = PendingActionService.get(); 
        PendingActionService.clean();   
        setUndoVisible(false);

        if (!pendingAction) return;
        if (pendingAction.result) {
            setSucceedCount(prev => prev - 1);
        } else {
            setFailedCount(prev => prev - 1);
        }   

        const card = getCard(pendingAction.id);
        if (!card) return;

        const newCard = {
            ...card,
            key: card.key + '_',
            visible: true
        };

        setCards(c => c.map(x => x.id === newCard.id ? newCard : x));
        setCurrentCard(newCard);
    };    
    
    useEffect(() => {
        mounted.current = true;
        return () => {
            mounted.current = false;
            forceExecuteAction();
        }; 
    }, []);

    return (
        <React.Fragment>
            {totalCount > 0 && <div>
                <SessionProgress failedCount={failedCount} succeedCount={succeedCount} testMode={props.mode === 'test'} totalCount={totalCount} />               
                {currentCard?.visible &&  
                    <Flashcard key={currentCard.id} mode={props.mode} definition={currentCard} isReverseTest={isReverseTestMode} onResponse={onResponse} onSave={updateCard} onRemove={remove} /> }
                <UndoDialog message='Your response was saved' onClose={handleUndoClose} onUndo={handleUndo} open={undoVisible} timeout={undoTimeout} />
            </div>} 
        </React.Fragment>
    );
};