export interface Definition {
    id: string,
    rating: number,
    word: string,
    containerId: string,
    memoryBox: string,
    definition: string,
    description: string,
    customDefinition: string,
    history: number[],
    pronunciations: any[],
    translations: string[],
    synonyms: string[],
    examples: any[],
    frequency: number,
    partOfSpeech: string,
    imageRef: string,
    color: string,
    notes: string,
    whiteboardRef: string,
    priority: number,
    grammaticalFeatures: any[],
    testVersion: number,
}

export const createEmptyDefinition = (word: string) =>  {return {
        customDefinition: '',
        definition: '',
        color: '#259dd5',
        imageRef: '',
        rating: 0,
        description: '',
        containerId: '',
        memoryBox: '',
        history: [],
        examples: [],
        frequency: 0, 
        priority: 0,
        synonyms: [],
        testVersion: 0,
        pronunciations: [],
        grammaticalFeatures: [],
        id: new Date().getTime() + "_",
        notes: '',
        partOfSpeech: "Other",
        translations: [],
        whiteboardRef: '',
        word: word.toLocaleLowerCase()
    } as Definition};

export interface UserSettings {
    lastTestDate: Date,
    dailyTestCapacity: number,
    reverseTestFrequency: number,
    sessionVersion: number,
}

export interface UserTestSet {
    toTest: Definition[],
    toLearn: Definition[]
}

export interface Example {
    translation: string,
    text: string,
}

export interface Meaning {
    id: string,
    selected: boolean,
    disabled: boolean,
    definition: string,
    partOfSpeech: string,
    translations: string[],
    examples: Example[],
}
