import React, { useState, useEffect, useContext, useRef, useCallback } from 'react';
import { LanguageContext } from "../LanguageContext";
import { VolumeUp } from 'react-bootstrap-icons';
import { ColorPicker } from '../Common/ColorPicker';
import { PhotoPicker } from './PhotoPicker';
import { FlashcardMenu } from './FlashcardMenu';
import { FlashcardToolbar } from './FlashcardToolbar';
import reactCSS from 'reactcss'
import { Whiteboard } from './Whiteboard'
import { WordGender } from './WordGender'
import { GrammaticalFeature } from './GrammaticalFeature'
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import useMediaQuery from '@mui/material/useMediaQuery';
import HttpCommon from '../../http-common';
import { InlineList } from '../Common/InlineList';
import { InlineListWithTooltips } from '../Common/InlineListWithTooltips';
import { WhiteboardDialog } from './WhiteboardDialog';
import { Definition } from '../../api-models';
import { Divider } from '@mui/material';
import { PartOfSpeach } from './PartOfSpeach';

export interface Flashcard2Props {
    definition: Definition;
    mode: "test" | "learn" | "edit";
    onClose: () => void;
    onCancel?: () => void;
    onResponse: (id: string, result: boolean, testVersion: number) => void;
    toggleCard: () => void;
    onSave: (definition: any, whiteboard: string) => Promise<any>;
    onRemove: (definition: any) => void;
    editMode: boolean;
}

export const FlashcardBack = (props: Flashcard2Props) => {
    console.log('FlashcardBack rendering')
    const [definition, setDefinition] = useState(props.definition);
    const [searchPhotoMode, setSearchPhotoMode] = useState(false);
    const [whiteboardMode, setWhiteboardMode] = useState(false);
    const [showNotes, setShowNotes] = useState(!!props.definition.notes);
    const mounted = useRef(false);
    const [draft, setDraft] = useState(props.editMode);
    const [whiteboard, setWhiteboard] = useState("");
    const { language, speakerCode, languageTarget, languageSource } = useContext(LanguageContext);
    const isMonoLanguage = languageTarget === languageSource;
    const matches = useMediaQuery('(min-width:768px)');
    let wordToSpeak = new SpeechSynthesisUtterance(definition.word);
    wordToSpeak.lang = speakerCode;
    let warmUp = new SpeechSynthesisUtterance("");
    warmUp.lang = speakerCode;
    window.speechSynthesis.speak(warmUp);
    const toggleCard = () => {
        props.toggleCard();
    }
    const onClose = () => {
        props.onClose();
    }
    const onResponse = (result: boolean) => {
        props.onResponse(definition.id, result, definition.testVersion);
    }

    const closePhotoBox = () => {
        setSearchPhotoMode(false);
    }

    const styles = reactCSS({
        'default': {
            meaning: !matches ? {
                borderBottomColor: `${definition?.color ?? "#259dd5"}`,
                fontSize: '1.7rem',
                lineHeight: '1',
                display: 'flex',
                alignItems: 'center',
            } : {
                borderBottomColor: `${definition?.color ?? "#259dd5"}`,
            },
            flashcardReverse: {
                borderLeftColor: `${definition?.color ?? "#259dd5"}`,
            },
            imageFilter: {
                filter: `opacity(0.9) drop-shadow(0 0 0 ${definition?.color ?? "#259dd5"})`
            },       
        }
    });

    const loadWhiteboard = () => {
        if (!props.definition.whiteboardRef) return;
        HttpCommon.getRaw(props.definition.whiteboardRef)
            .then(response => {
                setWhiteboard(response.data);
            });
    }

    const speak = (e: any) => {
        e.stopPropagation();
        console.log('speak')
        window.speechSynthesis.speak(wordToSpeak);
        return false;
    }
    const changePhoto = (photo) => {
        if (photo !== definition.imageRef) {
            setDefinition({ ...definition, imageRef: photo });
            setDraft(true);
        }
        setSearchPhotoMode(false);
        return false;
    }
    const onWhiteboardChange = useCallback((newVal) => {
        setWhiteboard(newVal);
        setDraft(true);
        setWhiteboardMode(false);
    }, [whiteboard, whiteboardMode]);

    const onWhiteboardCancel = useCallback(() => {
        setWhiteboardMode(false);
    }, [whiteboardMode]);

    const changeColor = useCallback((color) => {
        if (color !== definition.color) {
            setDefinition({ ...definition, color: color });
            setDraft(true);
        }
        return false;
    }, [draft, definition])

    const toggleSearchPhotoMode = useCallback(() => {
        setSearchPhotoMode(!searchPhotoMode);
    }, [searchPhotoMode]);

    const toggleShowNotes = useCallback(() => {
        setShowNotes(true);
    }, [showNotes]);

    const toggleWhiteboardMode = useCallback(() => {
        setWhiteboardMode(value => !value);
    }, [whiteboardMode]);

    const saveChanges = () => {
        return props.onSave(definition, whiteboard)
            .finally(() => {
                if (mounted.current) setDraft(false);
            });
    }
    const remove = useCallback(() => {
        props.onRemove(definition);
    }, [])
    const preventClick = (e: any) => {
        e.stopPropagation();
        return false;
    }
    const cancelChanges = () => {
        setDefinition(props.definition);
        setDraft(false);
        if (props.onCancel) {
            props.onCancel();
        }
    }
    const handleCustomDefinitionChange = (e: any) => {
        const newValue = e.target.value;
        setDefinition({ ...definition, customDefinition: newValue });
        setDraft(true);
    }

    const handleNotesChange = (e: any) => {
        const newValue = e.target.value;
        setDefinition({ ...definition, notes: newValue });
        setDraft(true);
    }

    const handleDefinitionChange = (e: any) => {
        const newValue = e.target.value;
        setDefinition({ ...definition, definition: newValue });
        setDraft(true);
    }

    const handleTranslationsChange = (e: any) => {
        const newValue = e.target.value;
        setDefinition({ ...definition, translations: [newValue] });
        setDraft(true);
    }

    const handlePartOfSpeechChange = (newValue: string, grammaticalFeatures: any[]) => {
        setDefinition({ ...definition, partOfSpeech: newValue, grammaticalFeatures: grammaticalFeatures });
        setDraft(true);
    }

    const handleWordChange = (e: any) => {
        const newValue = e.target.value;
        setDefinition({ ...definition, word: newValue });
        setDraft(true);
    }

    const isValid = (): boolean => {
        return !!definition.word && !!definition.partOfSpeech && (!!definition.translations?.join() || !!definition.definition);
    }

    useEffect(() => {
        mounted.current = true;
        loadWhiteboard();
        return () => {
            mounted.current = false;
        }; 
    }, []);

    return (
        <React.Fragment>
            <div className="flashcard-box back" style={styles.flashcardReverse}>
                <FlashcardMenu
                    toggleSearchPhotoMode={toggleSearchPhotoMode}
                    toggleWhiteboardMode={toggleWhiteboardMode}
                    toggleShowNotes={toggleShowNotes}
                    onRemove={remove}
                />
                <FlashcardToolbar
                    mode={props.mode}
                    draft={draft}
                    onResponse={(response) => {onResponse(response);}}
                    onSave={saveChanges}
                    canSave={isValid()}
                    onCancel={cancelChanges}
                    onReverse={toggleCard}
                    onClose={onClose}
                />
                <div className={`flashcard-body ${props.editMode ? 'edit-mode' : ''}`}>
                    {definition.imageRef && <div className="flashcard-area image"
                        onClick={toggleSearchPhotoMode}>
                        <img alt="flashcard image" style={styles.imageFilter} src={definition.imageRef} />
                    </div>}
                    {whiteboard && <div className="flashcard-area whiteboard">
                        <div className="whiteboard-2" onClick={toggleWhiteboardMode}>
                            <Whiteboard value={whiteboard}></Whiteboard>
                        </div>
                    </div>}
                    <div className="flashcard-area word">
                        { !props.editMode && <div className="flex-row word-box">
                             <div className="meaning" style={styles.meaning}>
                                 <WordGender partOfSpeech={definition.partOfSpeech} grammaticalFeatures={definition
                                     .grammaticalFeatures}/> {definition.word}
                             </div>
                             <div className="speaker" onClick={speak}> <VolumeUp size={ matches ? 28 : 24 }></VolumeUp></div>
                         </div>}
                        { props.editMode && 
                            <div className="flex-row word-box">
                                <TextField
                                    sx={{ marginBottom: 1 }}
                                    fullWidth
                                    id="notes"
                                    label="Definition"
                                    onClick={preventClick}
                                    autoComplete="off"
                                    required
                                    error={!definition.word}
                                    placeholder="definition..."
                                    onChange={handleWordChange}
                                    value={definition.word ?? ''}
                                />
                           </div>
                        }
                        <div><ColorPicker type="github" fullWidth={false} color={definition.color ?? "#259dd5"} onChange={changeColor}></ColorPicker></div>
                        <GrammaticalFeature grammaticalFeatures={definition.grammaticalFeatures} partOfSpeech={definition.partOfSpeech} languageSource={language}/>
                        <PartOfSpeach editMode={props.editMode} word={definition.word} partOfSpeech={definition.partOfSpeech} onChange={handlePartOfSpeechChange} grammaticalFeatures={definition.grammaticalFeatures}/>                        
                    </div>
                    { showNotes && <div className="flashcard-area notes">
                        <TextField fullWidth
                                   id="notes"
                                   label="Notes"
                                   multiline
                                   onClick={preventClick}
                                   placeholder="notes..."
                                   rows={matches ? 7 : 4}
                                   autoComplete="off"
                                   onChange={handleNotesChange}
                                   value={definition.notes ?? ''}
                        />
                    </div>}
                    <div className="flashcard-area info">                        
                        {props.editMode && <br/>}
                        {props.editMode && isMonoLanguage && <TextField
                            fullWidth
                            id="notes"
                            label="Definition"
                            onClick={preventClick}
                            autoComplete="off"
                            required
                            error={!definition.definition}
                            placeholder="definition..."
                            onChange={handleDefinitionChange}
                            value={definition.definition ?? ''}
                        />}
                        {props.editMode && !isMonoLanguage && <TextField
                            fullWidth
                            id="notes"
                            label="Translation"
                            onClick={preventClick}
                            autoComplete="off"
                            error={!definition.translations?.join()}
                            required
                            placeholder="translation..."
                            onChange={handleTranslationsChange}
                            value={definition.translations?.join() ?? ''}
                        />}                                                
                        {props.editMode && <br/>}
                        <TextField
                            fullWidth
                            id="notes"
                            label="Additional definition"
                            onClick={preventClick}
                            autoComplete="off"
                            placeholder="additional definition..."
                            onChange={handleCustomDefinitionChange}
                            value={definition.customDefinition ?? ''}
                        />
                        {!props.editMode && <React.Fragment>
                            {isMonoLanguage && definition.definition && <div>
                                <Divider>definition</Divider>
                                <span key={definition.definition + '_def'}>{definition.definition}</span>
                            </div>}
                            {definition.translations.length > 0 && <InlineList items={definition.translations} title={"translations"} />}
                            {definition.synonyms.length > 0 && <InlineList items={definition.synonyms} title={"synonyms"} />}
                            {definition.examples.length > 0 && <InlineListWithTooltips 
                                items={definition.examples} 
                                title={"examples"} />}
                        </React.Fragment> }
                    </div>
                </div>
            </div>
            <Dialog open={searchPhotoMode} fullWidth={true} maxWidth={"lg"} fullScreen={!matches}>
                <DialogTitle>Search photo</DialogTitle>
                <PhotoPicker
                    onClose={closePhotoBox}
                    onChange={changePhoto}></PhotoPicker>
            </Dialog>
            <WhiteboardDialog onCancel={onWhiteboardCancel} onSave={onWhiteboardChange} open={whiteboardMode} whiteboard={whiteboard} wideMode={matches} />
        </React.Fragment>
    );
};