import React, { useState, useEffect, useContext, useCallback } from 'react';
import { LanguageContext } from "../LanguageContext";
import { CardsSet } from './CardsSet';
import Button from '@mui/material/Button';
import Moment from 'react-moment';
import SettingsIcon from '@mui/icons-material/Settings';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useHistory } from 'react-router-dom';
import { learningGetTestSetCall, learningStartTestSetCall, userSettingsGetCall, userSettingsUpdateCall } from '../../api-definitions';
import { Pending } from '../Common/Pending';
import { Container } from 'reactstrap';
import { TestSetDialog } from './TestSetDialog';
import { SessionSummary } from './SessionSummary';
import { UserSettings, UserTestSet } from '../../api-models';

export const TestSet = () => {
    console.log('testSet is rendering...')
    const [userSettings, setUserSettings] = useState<UserSettings>({} as UserSettings);
    const [testSet, setTestSet] = useState<UserTestSet>({} as UserTestSet);
    const [loaded, setLoaded] = useState(false);
    const [startPending, setStartPending] = useState(false);
    const [showUserSettings, setShowUserSettings] = useState(false);
    const [showLearn, setShowLearn] = useState(false);
    const [showSummary, setShowSummary] = useState(false);
    const { language } = useContext(LanguageContext);
    const matches = useMediaQuery('(min-width:768px)');
    const history = useHistory();

    const getTestSet = async () => {
        const response = await learningGetTestSetCall(language);
        setTestSet(response);
        setLoaded(true);
    }

    const onTestComplete = () => {
        setShowLearn(true);
        if (testSet.toLearn.length === 0) {
            setShowSummary(true);
        }
    }

    const onLearnComplete = () => {
        setShowSummary(true);
    }

    const isNewSessionEnabled = () => {
        console.log('isNewSessionEnabled')
        return !!testSet && (testSet.toTest.length > 0 || testSet.toLearn.length > 0)
    };

    const getSettings = async () => {
        const response = await userSettingsGetCall(language);
        setUserSettings(response);
    }

    const updateSettings = useCallback((newCapacity, newReverseTestFrequency) => {
        userSettingsUpdateCall(language, newCapacity, newReverseTestFrequency)
            .then(data => getSettings())
            .then(data => setShowUserSettings(false));
    }, [language]);


    const start = () => {
        setStartPending(true);
        learningStartTestSetCall(language, { sessionVersion: userSettings.sessionVersion })
            .then(data => getSettings())
            .then(data => getTestSet())
            .then(data => {
                setShowSummary(false);
                setStartPending(false);
            });
    }

    const cancelSettings = useCallback(() => {
        setShowUserSettings(false);
    }, [])
    
    useEffect(() => {
        getSettings()
            .then(data => getTestSet());;
    }, []);

    useEffect(() => {
        if (testSet.toTest && testSet.toTest.length + testSet.toLearn.length === 0) {
            setShowSummary(true);
        }
        else if (testSet.toTest && testSet.toTest.length === 0) {
            setShowLearn(true);
        }
    }, [testSet]);

    useEffect(() => {
        if (!language) {
            history.push("/plan");
        }
    }, [language]);

    return (
        <Container>
            <h3>Learning session</h3>
            { !loaded && <Pending /> }
            { loaded &&  <React.Fragment>
                <TestSetDialog open={showUserSettings} dailyTestCapacity={userSettings.dailyTestCapacity} reverseTestFrequency={userSettings.reverseTestFrequency} onSave={updateSettings} onCancel={cancelSettings}/>
                <div>
                    {userSettings?.lastTestDate && <span>Started: <Moment fromNow>{userSettings?.lastTestDate}</Moment></span>}
                    <Button disabled={startPending || isNewSessionEnabled()} style={{ margin: "0 10px" }} variant="outlined" size={matches ? "medium" : "small" }
                            onClick={start}>
                        Start new {matches ? "session" : ""}
                    </Button>
                    <Button variant="outlined" onClick={() => { setShowUserSettings(p => !p) }} size={matches ? "medium" : "small"}>
                        <SettingsIcon />
                    </Button>
                </div>
                {!startPending && <div className="card-set-box">
                    <CardsSet cards={testSet.toTest} mode={'test'} lastTestedDate={userSettings.lastTestDate} sessionVersion={userSettings.sessionVersion} onComplete={onTestComplete} reverseTestFrequency={userSettings.reverseTestFrequency} />
                    {showLearn && <CardsSet cards={testSet.toLearn} mode={'learn'} lastTestedDate={userSettings.lastTestDate} sessionVersion={userSettings.sessionVersion} onComplete={onLearnComplete} reverseTestFrequency={userSettings.reverseTestFrequency} />}                    
                    {showSummary && <SessionSummary lastTestedDate={userSettings.lastTestDate} />}   
                </div>}
            </React.Fragment>}
        </Container>
    );
};