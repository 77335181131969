import React from 'react';
import { SketchField, Tools } from 'react-sketch';

export interface WhiteboardProps {
    value: string,
}

export const Whiteboard = React.memo((props: WhiteboardProps) => {
    console.log('Whiteboard rendering')
    return (
        <React.Fragment>
            <div className="whiteboard-box">
                <SketchField
                    width='300px'
                    tool={Tools.Select}
                    height='200px'
                    lineColor="transparent"
                    lineWidth="0"
                    value={props.value}
                />
                <div className="whiteboard-front"></div>
            </div>
            
        </React.Fragment>
    );
});