import React from 'react';
import { Route } from 'react-router';
import Layout  from './components/Layout';
import { TestSet } from './components/Learning/TestSet';
import { Definitions } from './components/Definitions/Definitions';
import { AlphabetTest } from './components/Alphabet/AlphabetTest';
import { AddDefinitions } from './components/AddDefinitions/AddDefinitions';
import { UserPlan } from './components/UserPlans/UserPlan';
import './custom.css'


export default () => (
    <Layout>
        <Route exact path='/' component={AddDefinitions} />
        <Route path='/add' component={AddDefinitions} />
        <Route path='/test' component={TestSet} />
        <Route path='/definitions' component={Definitions} />
        <Route path='/alphabet' component={AlphabetTest} />
        <Route path='/plan' component={UserPlan} />
    </Layout>
);