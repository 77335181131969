import React, { CSSProperties } from 'react';
import Divider from '@mui/material/Divider';

export interface InlineListProps {
    items: string[],
    title: string,
    style?: CSSProperties | undefined,
}

export const InlineList = React.memo((props: InlineListProps) => {
    console.log('InlineList rendering')
    const isNotLastItem = (index: number) : boolean => index + 1 < props.items.length;
    return (
        <div>
            {props.title && <Divider>{props.title}</Divider>}
            {props.items.map((item, i) =>
                <span key={item + i} style={props.style}>{item}{isNotLastItem(i) && <React.Fragment>,&nbsp;</React.Fragment>}</span>
            )}
        </div>
    );
});