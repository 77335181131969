import React, { useState, useEffect, useContext, useCallback } from 'react';
import { LanguageContext } from "../LanguageContext";
import {  getSourceFlagUrl, getTargetFlagUrl } from "../Common/Flags";
import { userSettingsDeleteCall, userSettingsGetAllCall } from '../../api-definitions';
import { Pending } from '../Common/Pending';
import { Container } from 'reactstrap';
import { DeleteDialog } from './DeleteDialog';
import { ListItem } from './ListItem';
import { CreateBox } from './CreateBox';
import './UserPlan.css';

export const UserPlan = () => {
    console.log('UserPlan rendering')
    const [loaded, setLoaded] = useState(false);
    const [userSettings, setUserSettings] = useState([] as any[]);
    const { language, setLanguage } = useContext(LanguageContext);
    const [open, setOpen] = useState(false);
    const [selectedPlan, setSelectedPlan] = useState({} as any)

    const getAllSettings = (selectAfterLoaded: 'first' | 'last' | 'none' = 'none') => {
        return userSettingsGetAllCall(language)
            .then(response => {
                const resp = response.data.map((plan: any) => {
                    return {
                        ...plan,
                        flagSource: getSourceFlagUrl(plan.language),
                        flagTarget: getTargetFlagUrl(plan.language),
                    }
                });
                setUserSettings(resp);
                setLoaded(true);
                autoSelectPlan(resp, selectAfterLoaded);
            });
    }

    const autoSelectPlan = (plans: any[], selectMode: 'first' | 'last' | 'none' = 'none') => {
        if (plans.length === 0) return;
        if (selectMode === 'first') {
            selectPlan(plans[0]);
        }
        if (selectMode === 'last') {
            selectPlan(plans[plans.length - 1]);
        }
        if (selectMode === 'none') {
            let index = plans.findIndex(x => x.language === language);
            if (index === -1)
                index = 0;
            selectPlan(plans[index]);
        }
    }

    const selectPlan = (plan) => {
        setLanguage(plan.language);
    };

    const selectPlanCallback = useCallback((plan) => () => {
        selectPlan(plan);
    }, []);

    const openDeleteDialog = useCallback((plan) => () => {
        setSelectedPlan(plan);
        setOpen(true);
    }, []);
    
    const handleClose = useCallback(() => {
        setSelectedPlan({});
        setOpen(false);
    }, []);

    const onCreated = useCallback(() => {
        getAllSettings('last');
    }, []);

    const onDelete = useCallback(async () : Promise<any> => {
        await userSettingsDeleteCall(selectedPlan.language);             
        setSelectedPlan({});
        await getAllSettings('first');
        setOpen(false);   
    }, [selectedPlan, userSettings]);

    useEffect(() => {
        getAllSettings();
    }, []);

    return (
        <Container>
            <h3>Select language</h3>
            { loaded && <div className='user-plan'>
                <table className='table table-striped' aria-labelledby="tabelLabel">
                    <tbody>
                        {userSettings.map((plan: any) =>                           
                            <ListItem key={plan.language} plan={plan} currentLanguage={language} onSelect={selectPlanCallback} onDeleteClick={openDeleteDialog} />
                        )}
                    </tbody>
                </table>                            
                <DeleteDialog open={open} onDelete={onDelete} onClose={handleClose} />
                <CreateBox onCreated={onCreated} />
            </div>}
            { !loaded && <Pending />}
        </Container>
    );
};