import React, { useState, useContext, useCallback, useRef, useEffect } from 'react';
import { LanguageContext } from "../LanguageContext";
import { FlashcardEdit } from '../Flashcard/FlashcardEdit';
import { wordsDeleteCall, wordsUpdateCall } from '../../api-definitions';
import Button from '@mui/material/Button';
import AddIcon from '@mui/icons-material/Add';
import { DefinitionsListItem } from './DefinitionsListItem';
import { Dialog } from '@mui/material';
import { Definition } from '../../api-models';

export interface DefinitionsListProps {
    definitions: Definition[],
    onRemove: (definition: Definition) => void,
    getMore?: () => void,
    isMore: boolean,
    onSave: (definition: Definition) => void,
}

export const DefinitionsList = (props: DefinitionsListProps) => {
    console.log('DefinitionsList rendering')
    const [selected, setSelected] = useState({} as any);
    const { language } = useContext(LanguageContext);
    const mounted = useRef(false);

    const remove = (definition) => {
        return wordsDeleteCall(definition.id, language)
            .then(data => {
                props.onRemove(definition);
                if (mounted.current) setSelected({});
            });
    }
    const cancel = () => {
        setSelected({});
    }

    const onClick = useCallback((definition) => {
        setSelected(definition);
    }, []);

    const save = (definition: Definition, whiteboard: string) => {
        return wordsUpdateCall(definition.id, language, definition, whiteboard)
            .then(response => {
                props.onSave(response);
                if (mounted.current) setSelected({});
            });
    }
    
    useEffect(() => {
        mounted.current = true;
        return () => {
            mounted.current = false;
        }; 
    }, []);

    return (
        <React.Fragment>
            {props.definitions.length > 0 && <div>
                <table className='table table-striped' aria-labelledby="tabelLabel">
                    <tbody>
                    {props.definitions.map(definition =>
                        <DefinitionsListItem key={definition.id} definition={definition} onClick={onClick} />
                    )}
                    { props.isMore && <tr key="xyz">
                        <td colSpan={7}>
                            <Button className="toolbar-btn" variant="outlined" onClick={props.getMore} endIcon={<AddIcon/>}>
                                Load more
                            </Button>
                        </td>
                    </tr>}
                    </tbody>
                </table>
                <Dialog open={!!selected.id} maxWidth={'md'} fullWidth={true}>
                    <FlashcardEdit editMode={false} definition={selected} onSave={save} onClose={cancel} onRemove={remove} />
                </Dialog> 
             </div>             
            }                    
        </React.Fragment>
    );
};