import { Button, CircularProgress } from '@mui/material'
import React from 'react'

export const LoadingButton = (props) => {
  console.log('LoadingButton render')
  const {
    children,
    loading,
    ...rest
  } = props
  
  return (
    <Button {...rest}>
      {children}
      {loading && <CircularProgress disableShrink sx={{marginLeft: '5px'}} size={18} />}
    </Button>
  )
}