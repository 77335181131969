import React from 'react';
import { CheckSquare, Square } from 'react-bootstrap-icons';
import { Meaning } from '../../api-models';
import { InlineList } from '../Common/InlineList';
import { InlineListWithTooltips } from '../Common/InlineListWithTooltips';

export interface MeaningsListItemProps {
    meaning: Meaning,
}

export const MeaningsListItem = React.memo((props: MeaningsListItemProps) => {
    console.log('MeaningsListItem rendering')
    return (
        <React.Fragment>
            <td><div>
                {props.meaning.selected && <CheckSquare />}
                {!props.meaning.selected && <Square />}
            </div></td>
            <td>{props.meaning.partOfSpeech}</td>
            {props.meaning.definition && <td>{props.meaning.definition}</td>}
            <td> {props.meaning.translations.length > 0 && 
                <InlineList items={props.meaning.translations} title={''} /> 
            }</td>
            <td>{props.meaning.examples.length > 0 && 
                <InlineListWithTooltips items={props.meaning.examples} title={''} /> 
            }</td>
        </React.Fragment>
    );
});