import React, { useRef, useLayoutEffect, useState } from 'react';
import TextField from "@mui/material/TextField";
import Button from '@mui/material/Button';
import { searchPhotosCall } from '../../api-definitions';
import { Pending } from '../Common/Pending';
import Stack from '@mui/material/Stack';
import Container from '@mui/material/Container';
import './PhotoPicker.css';

export interface PhotoPickerProps {
    onClose: () => void;
    onChange: (photo: string) => void;
}

export interface Photos {
    results: any[]
}

export const PhotoPicker = (props: PhotoPickerProps) => {
    console.log('PhotoPicker rendering')
    const [photos, setPhotos] = useState({} as Photos);
    const [searchPhotoText, setSearchPhotoText] = useState('');
    const [searchPhotosPending, setSearchPhotosPending] = useState(false);
    const inputRef = useRef<any>(null);

    const searchPhotos = () => {
        searchPhotosCall(searchPhotoText)
            .then(response => {
                setSearchPhotoText('');
                setSearchPhotosPending(false);
                setPhotos(response.data);
            });
    }

    const cleanPhoto = () => {
        props.onChange("");
        inputRef.current.focus();
    }

    const closePhotoBox = () => {
        props.onClose();
    }

    const handleSearchPhotoChange = (e: any) => {
        const newValue = e.target.value;
        setSearchPhotoText(newValue);
    }
    const changePhoto = (e: any, photo) => {
        props.onChange(photo.urls.thumb);
    }    

    useLayoutEffect(() => {
        if (inputRef.current) {
            inputRef.current.focus();
        }
    }, []);

    return (
        <React.Fragment>
            {searchPhotosPending && <Pending />}
            {!searchPhotosPending && <Container className="photo-picker">
                <div className="form-group">
                    <TextField
                        fullWidth
                        inputRef={inputRef}
                        placeholder="search..."
                        autoComplete="off"
                        label="Search photos"
                        id="nameInput"
                        onChange={handleSearchPhotoChange}
                        value={searchPhotoText}
                    />
                </div>
                <Stack direction={{ xs: 'column', sm: 'row' }} spacing={{ xs: 1, sm: 2 }} >                
                    <Button variant="contained" onClick={searchPhotos}>Search</Button>
                    <Button variant="outlined" onClick={closePhotoBox}>Close</Button>
                    <Button variant="outlined" onClick={cleanPhoto}>Clean</Button>           
                </Stack>
                
                {photos && photos.results && <div className="flex-row">
                    {photos.results.map(photo =>
                        <div className="image" key={photo.id} onClick={(e) => changePhoto(e, photo)}>
                            <img alt={"photo"} src={photo.urls.thumb}></img>
                        </div>
                    )}
                </div>}
            </Container>}
        </React.Fragment>
    );
};