import React from 'react';
import styled from 'styled-components';
import countries from '../../data/countries.json';

export interface FlagsProps {
    language: string,
    flagSource: string
    flagTarget: string
}

const PlansLink = styled.div`
    display: flex;
`;
const PlansFlagsBox = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin: 0 5px;
`;
const FlagBox = styled.div`
    border: 1px solid silver;
    background-image: url(${props => props.flag});
    content: "";
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    width: 15px;
    height: 10px;
    display: inline-block;
    margin: 1px 0;
    border-radius: 1px;
`;

export const Flags = React.memo((props: FlagsProps) => {    
    console.log('Flags rendering')    
    return (
        <PlansLink>
            <div>{props.language}</div>
            <PlansFlagsBox>
                <FlagBox flag={props.flagSource}></FlagBox>
                <FlagBox flag={props.flagTarget}></FlagBox>
            </PlansFlagsBox>
        </PlansLink>
    );
});

export const getFlagUrl = (flag: string | undefined) => {
    if (!flag) return "";
    return 'https://purecatamphetamine.github.io/country-flag-icons/3x2/' +  flag.toUpperCase() + '.svg';
}

export const getSourceFlagUrl = (language: string) => {
    const flag: string | undefined = countries.find(x => x.key === language.split('/')[0])?.flagCode;
    return getFlagUrl(flag);
}

export const getTargetFlagUrl = (language: string) => {
    const flag: string | undefined = countries.find(x => x.key === language.split('/')[1])?.flagCode;
    return getFlagUrl(flag);
}