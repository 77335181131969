import React, { useState, MutableRefObject, useEffect } from 'react';
import FormControl from '@mui/material/FormControl';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import { TextInputWithKeyboard } from "./TextInputWithKeyboard";
import { toKana, toHiragana, toKatakana, toRomaji } from 'wanakana';
import useMediaQuery from '@mui/material/useMediaQuery';
import IconButton from '@mui/material/IconButton';
import './JapanTextInput.css';
import CachedIcon from '@mui/icons-material/Cached';
const KanjiWrapper = React.lazy(() => import('./KanjiWrapper'));

export interface Child2Props {
    onChange: (e: any) => void,
    onEnter: () => void,
    allowEmpty: boolean,
    value: string,
    inputRef: MutableRefObject<any>,
    disableKeyboardFocus?: boolean,
}

export function translateValue(string, type) {
    console.log('translate')
    switch (type) {
    case 'romaji':
        return toRomaji(string, { IMEMode: true });
    case 'hiragana':
        return toHiragana(string, { IMEMode: true });
    case 'katakana':
        return toKatakana(string, { IMEMode: true });
    case 'kana':
    default:
        return toKana(string, { IMEMode: true });
    }
}

export const JapanTextInput = (props: Child2Props) => {
    console.log('JapanTextInput render')
    const [hiraganaValue, setHiraganaValue] = useState(translateValue(props.value, 'hiragana'));
    const [romajiValue, setRomajiValue] = useState(translateValue(props.value, 'romaji'));
    const [katakanaValue, setKatakanaValue] = useState(translateValue(props.value, 'katakana'));
    const [mode, setMode] = useState('hiragana');
    const matches = useMediaQuery('(min-width:768px)');

    const isHiragana = () => mode === 'hiragana';
    const isRomaji = () => mode === 'romaji';
    const isKatakana = () => mode === 'katakana';

    const keyDownHandler = (event: React.KeyboardEvent<HTMLInputElement>) => {
        if ((event.code === "Enter" || event.keyCode === 13 || event.key === "Enter") && hiraganaValue) {
            props.onEnter();
        }
    };
    
    const onChangeHiraganaInput = (event: any): void => {
        const input = event.target.value;
        onChangeHiragana(input);
    };

    const onChangeKatakanaInput = (event: any): void => {
        const input = event.target.value;
        onChangeKatakana(input);
    };

    const onChangeRomajiInput = (event: any): void => {
        const input = event.target.value;
        onChangeRomaji(input);
    };

    const onChangeHiragana = (input: string): void => {
        setHiraganaValue(input);
        setRomajiValue(translateValue(input, 'romaji'));
        setKatakanaValue(translateValue(input, 'katakana'));        
        setTimeout(() => props.onChange(translateValue(input, mode)), 150);
    };

    const onChangeKatakana = (input: string): void => {
        setKatakanaValue(input);
        setRomajiValue(translateValue(input, 'romaji'));
        setHiraganaValue(translateValue(input, 'hiragana'));
        setTimeout(() => props.onChange(translateValue(input, mode)), 150);
    };

    const onChangeRomaji = (input: string): void => {
        setRomajiValue(input);
        setKatakanaValue(translateValue(input, 'katakana'));
        setHiraganaValue(translateValue(input, 'hiragana'));
        setTimeout(() => props.onChange(translateValue(input, mode)), 150);
    };

    const onChangeKanjiInput = (newKanji: string): void => {
        onChangeHiragana(hiraganaValue + newKanji);
    };
    
    const switchMode = (event: any): void => {
        const newMode = isHiragana() ? 'katakana' : isKatakana() ? 'romaji' : 'hiragana';
        setMode(newMode);        
    };

    const getValue = (): string => {
        return isHiragana() ? hiraganaValue : isKatakana() ? katakanaValue : romajiValue;
    };
    
    useEffect(() => {
        props.onChange(getValue());
    }, [mode]);

    const switchButton = <IconButton
        aria-label="toggle"
        color="primary"
        onClick={switchMode}
        edge="end" >
        <CachedIcon />
    </IconButton>;

    return (
        <React.Fragment>
            <div className="flex-row japanese-text" style={{ marginBottom: '10px', justifyContent: matches ? 'start' : 'space-between'}}>
            <React.Suspense fallback={<p>Loading...</p>}>
                <KanjiWrapper label={"Kanji"} onChange={onChangeKanjiInput} />
            </React.Suspense>
            {!isRomaji() && <FormControl className='small-input' variant="outlined" sx={{ width: matches ? '30ch' : '15ch' }}>
                <InputLabel htmlFor="search-romaji">Search (Romaji)</InputLabel>
                <OutlinedInput
                    id="search-romaji"
                    value={romajiValue}
                    onChange={onChangeRomajiInput}
                    label="Search (Romaji)"
                    autoComplete="off"
                    onKeyDown={keyDownHandler} />
            </FormControl>}
            {!isKatakana() && <FormControl className='small-input' variant="outlined" sx={{ width: matches ? '30ch': '15ch' }}>
                <InputLabel htmlFor="search-katakana">Search (Katakana)</InputLabel>
                <OutlinedInput
                    id="search-katakana"
                    value={katakanaValue}
                    onChange={onChangeKatakanaInput}
                    label="Search (Katakana)"
                    autoComplete="off"
                    onKeyDown={keyDownHandler} />
            </FormControl>}
            {!isHiragana() && <FormControl className='small-input' variant="outlined" sx={{ width: matches ? '30ch': '15ch' }}>
                <InputLabel htmlFor="search-hiragana">Search (Hiragana)</InputLabel>
                <OutlinedInput
                    id="search-hiragana"
                    value={hiraganaValue}
                    label="Search (Hiragana)"
                    onChange={onChangeHiraganaInput}
                    autoComplete="off"
                    onKeyDown={keyDownHandler} />
            </FormControl>}
            </div>
            {isHiragana() &&<TextInputWithKeyboard keyboard={"japanese"} label="Search (Hiragana)" inputRef={props.inputRef} value={hiraganaValue} onEnter={props.onEnter} onChange={onChangeHiragana} disableKeyboardFocus={true} iconButton={switchButton} allowEmpty={props.allowEmpty} />}
            {isKatakana() &&<TextInputWithKeyboard keyboard={"katakana"} label="Search (Katakana)" inputRef={props.inputRef} value={katakanaValue} onEnter={props.onEnter} onChange={onChangeKatakana} disableKeyboardFocus={true} iconButton={switchButton} allowEmpty={props.allowEmpty} />}
            {isRomaji() &&<TextInputWithKeyboard keyboard={"english"} label="Search (romaji)" inputRef={props.inputRef} value={romajiValue} onEnter={props.onEnter} onChange={onChangeRomaji} disableKeyboardFocus={true} iconButton={switchButton} allowEmpty={props.allowEmpty} />}
        </React.Fragment>
    );
};