import React, { MutableRefObject } from 'react';
import { TextInputWithKeyboard } from "./TextInputWithKeyboard";
import { JapanTextInput } from "./JapanTextInput";

export interface MultiScriptTextInputProps {
    onChange: (e: any) => void,
    onEnter: () => void,
    value: string,
    label: string,
    keyboard: string,
    allowEmpty: boolean,
    multiInputsMode: boolean,
    disableKeyboardFocus?: boolean,
    inputRef: MutableRefObject<any>
}
export const MultiScriptTextInput = (props: MultiScriptTextInputProps) => {
    console.log('MultiScriptTextInput render')
    const japanMultiInputsMode = props.keyboard === "japanese" && props.multiInputsMode;
    return (
        <React.Fragment>
            {japanMultiInputsMode && <JapanTextInput
                onEnter={props.onEnter}
                allowEmpty={props.allowEmpty}
                value={props.value}
                inputRef={props.inputRef}
                disableKeyboardFocus={props.disableKeyboardFocus}
                onChange={props.onChange} />}
            {!japanMultiInputsMode && <TextInputWithKeyboard
                label={props.label}
                value={props.value}
                inputRef={props.inputRef}
                allowEmpty={props.allowEmpty}
                keyboard={props.keyboard}
                disableKeyboardFocus={props.disableKeyboardFocus}
                onEnter={props.onEnter}
                onChange={props.onChange} />}
        </React.Fragment>
    );
};