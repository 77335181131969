import * as React from 'react';
import reactCSS from 'reactcss'
import { Container } from 'reactstrap';
import { NavMenu } from './NavMenu';
import { LanguageProvider } from "./LanguageContext";
import { MsalAuthenticationTemplate } from "@azure/msal-react";
import { InteractionType } from "@azure/msal-browser";
import CircularProgress from '@mui/material/CircularProgress';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import Box from '@mui/material/Box';

const theme = createTheme({
    palette: {
      primary: {
        main: '#259dd5',
      },
    },
});

declare module '@mui/material/styles' {
    interface Palette {
        neutral: Palette['primary'];
    }

    interface PaletteOptions {
        neutral?: PaletteOptions['primary'];
    }
}

const Layout = ( props: { children?: React.ReactNode }) => {    
    const layoutStyle = reactCSS({
        'default': {
            loader: {
                justifyContent: 'center',
                height: '300px',
                flexDirection: 'row',
                alignItems: 'center',
            }
        }
    });

    const ErrorComponent = ({ error }) => {
        return <p>An Error Occurred: {error}</p>;
    }

    const LoadingComponent = () => {
        return <Box style={layoutStyle.loader} sx={{ display: 'flex' }}>
            <CircularProgress />
        </Box>;
    }

    const authRequest = {
        scopes: ["openid"]
    };

    return (  
        <MsalAuthenticationTemplate
            interactionType={InteractionType.Redirect}
            authenticationRequest={authRequest}
            errorComponent={ErrorComponent}
            loadingComponent={LoadingComponent}>
            <React.Fragment>
                <LanguageProvider>                    
                <ThemeProvider theme={theme}>
                    <NavMenu />
                    <div className='main-view'>
                        {props.children}
                    </div>
                </ThemeProvider>
                </LanguageProvider>
            </React.Fragment>
        </MsalAuthenticationTemplate>
    );
};

export default Layout;