import React from 'react';
import styled from 'styled-components';
import { useMediaQuery } from '@mui/material';
import logo from '../assets/logo.png';
import { Flags } from "./Common/Flags";

export interface LogoProps {
    flagSource: string,
    flagTarget: string,
    language: string,
}

const LogoBox = styled.div`
    display: flex;
    `;
export const Logo = (props: LogoProps) => {
    console.log('Logo render')
    const matches = useMediaQuery('(min-width:576px)');
    return (
        <LogoBox>
            <img
                src={logo}
                height="40"
                className="d-inline-block align-top"
                alt="Fluent Languages"
            />
            {!matches && props.language && <Flags language={""} flagSource={props.flagSource} flagTarget={props.flagTarget} ></Flags>}
        </LogoBox>
    );
};