import React, { Component } from 'react';
import { userSettingsGetCall } from '../api-definitions';
import countries from '../data/countries.json';
import { getFlagUrl } from "./Common/Flags";

export interface IThemeContext {
    language: string,
    languageTarget: string,
    languageSource: string,
    languageSourceName: string,
    speakerCode: string,
    refreshLanguage: () => void,
    setLanguage: (language: string) => void,
    flagSource: string,
    alphabetTest: boolean,
    flagTarget: string,
    country: any,
    loaded: boolean
}

export interface IThemeStore {
    language: string,
    languageTarget: string,
    languageSource: string,
    languageSourceName: string,
    speakerCode: string,
    flagSource: string,
    flagTarget: string,
    alphabetTest: boolean,
    country: any,
    loaded: boolean
}

export const LanguageContext = React.createContext<IThemeContext>({
    language: '',
    languageTarget: '',
    languageSource: '',
    languageSourceName: '',
    speakerCode: '',
    refreshLanguage: () => { },
    setLanguage: (language: string) => { },
    flagSource: '',
    flagTarget: '',
    alphabetTest: false,
    country: {},
    loaded: false
});

export class LanguageProvider extends Component<{}, IThemeStore> {
    resolveLanguage = () => {
        const langCode = localStorage.getItem("language");
        if (langCode) {
            this.getSettings(langCode)
                .then(data => {
                    if (data) {
                        const langParts = langCode.split("/");
                        const country: any = countries.find(x => x.key === langParts[0]);
                        const countryTarget: any = countries.find(x => x.key === langParts[1]);
                        this.setState({
                            language: langCode ?? "",
                            languageTarget: langParts[1],
                            languageSource: langParts[0],
                            languageSourceName: country?.name ?? "",
                            speakerCode: country?.BCP47 ?? "",
                            alphabetTest: !!country.alphabetTest,
                            flagSource: getFlagUrl(country.flagCode),
                            flagTarget: getFlagUrl(countryTarget.flagCode),
                            country: country,
                            loaded: true
                        });
                    }
                    else {
                        localStorage.setItem("language", "");
                        this.setState({
                            language: '',
                            languageTarget: '',
                            languageSource: '',
                            languageSourceName: '',
                            speakerCode: '',
                            alphabetTest: false,
                            flagSource: '',
                            flagTarget: '',
                            country: {},
                            loaded: true
                        });
                    }
                });
        } else {
            this.setState({
                language: '',
                languageTarget: '',
                languageSource: '',
                languageSourceName: '',
                speakerCode: '',
                alphabetTest: false,
                flagSource: '',
                flagTarget: '',
                country: {},
                loaded: true
            });
        }
    }

    componentDidMount() {
        this.resolveLanguage();
    }

    getSettings = (language) => {       
        return new Promise((resolve, reject) => {
            userSettingsGetCall(language)
                .then(data => resolve(data))
                .catch(err => reject());
        });
    }

    state = {
        language: '',
        languageTarget: '',
        languageSource: '',
        languageSourceName: '',
        speakerCode: '',
        flagSource: '',
        flagTarget: '',
        alphabetTest: false,
        country: {},
        loaded: false
    };

    setLanguage = (language: string) => {
        localStorage.setItem("language", language);
        this.resolveLanguage();
    }

    render() {
        const { children } = this.props;
        const {
            language,
            languageTarget,
            languageSource,
            languageSourceName,
            speakerCode,
            flagSource,
            flagTarget,
            country,
            alphabetTest,
            loaded
        } = this.state;
        const { resolveLanguage, setLanguage } = this;

        return (
            <LanguageContext.Provider
                value={{
                    language: language,
                    languageTarget: languageTarget,
                    languageSource: languageSource,
                    languageSourceName: languageSourceName,
                    refreshLanguage: resolveLanguage,
                    setLanguage: setLanguage,
                    flagSource: flagSource,
                    flagTarget: flagTarget,
                    speakerCode: speakerCode,
                    alphabetTest: alphabetTest,
                    country: country,
                    loaded: loaded
                }}>
                { loaded && children}
            </LanguageContext.Provider>);
        }    
}
